#footer {
  width: 100vw;
  // height: 400px;
  padding: 50px 120px;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .footer-bottom {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: flex-end;
    width: 90vw;
    margin: 0 auto;

    span {
      color: rgba(0, 0, 0, 0.3);
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1.8px;

      a {
        color: #000;
        letter-spacing: 0px;

        span {
          color: #ff00a8;
        }
      }
    }

    span:last-child {
      font-size: 8px;
      letter-spacing: 1.2px;
    }
  }
}
.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: white;

  .footer-menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100vw;

    .footer-column {
      width: 20%;
      text-transform: uppercase;

      h5 {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 2px;
        margin-bottom: 10px;
      }
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;

        a {
          color: rgba(0, 0, 0, 0.8);
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 2.6px;
        }
      }
      .newsletter {
        display: flex;
        width: 300px;
        height: 25px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.75px solid #e6e6e6 !important;
        border: none;
        color: #cfcfcf;
        padding: 15px 0;

        #email {
          width: 90%;
        }

        .subscribed {
          color: rgb(129, 168, 129);
        }

        input {
          border: none;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 2px;
          text-transform: uppercase;
          width: 100%;
          background-color: white !important;

          &:active {
            outline: none;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.he .footer-container {
  direction: rtl;
}

// RESPONSIVE
@media (max-width: 768px) {
  #footer {
    width: 100%;
    padding: 30px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;

    .footer-bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      margin: auto;

      span {
        font-size: 8px;
        letter-spacing: 1.8px;
      }
    }
  }
  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: white;

    .footer-menu {
      display: flex;
      justify-content: space-between;
      width: 100vw;
      flex-wrap: wrap;
      padding: 0 20px;

      .footer-column {
        width: 100%;
        text-align: center;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          width: 33%;
        }
        &:nth-child(4) {
          width: 100%;
        }

        h5 {
          font-size: 12px;
          letter-spacing: 1.5px;
          margin: 20px 0 10px 0;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
          li {
            display: flex;
            justify-content: center;
            height: min-content;
          }
          a {
            font-size: 8px;
            letter-spacing: 1.5px;
          }
        }
        .newsletter {
          display: flex;
          width: 50%;
          height: 25px;
          justify-content: center;
          align-items: center;
          border: none;
          margin: auto;
          padding: 15px 0;

          #email {
            width: 100%;
          }

          input {
            font-size: 10px;
            letter-spacing: 1.2px;
            width: 100%;
          }
        }
      }
    }
  }
}
