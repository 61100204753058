.filters-container {
  width: 65%;
  height: 80px;
  border: 2px solid #000;
  background: #fff;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  z-index: 99999;
  position: sticky;
  bottom: 3vh;
  // left: 50%;
  // transform: translate(-25%, -0%);
  transition: width 0.8s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    height 2s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &.show {
    width: 85%;
    height: 530px;
    // left: 7vw;
    overflow-y: scroll;

    .filters-options,
    .filters-buttons {
      opacity: 1;
      transition: all 0.9s ease-in-out;
    }

    .filters-buttons {
      display: flex;
      position: sticky;
      bottom: 0;
      background: #fff; // to make the item behind the buttons invisible
    }
  }

  .filters-header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    border-bottom: 1px solid #f0f0f0;
    transition: all 0.4s ease-in-out;

    span {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .filters-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    // gap: 20px;
    padding: 30px 15px;
    height: max-content;
    opacity: 0;
    transition: all 0.9s ease-in-out;
    // height: 350px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 5px;

      .icon {
        display: none;
      }
    }

    label,
    span {
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    .options-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .colors__options,
    .sizes__options,
    .categories__options {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      // width: 25%;
      padding: 0 12px;
      height: 100%;
      border-right: 1px solid #f0f0f0;
    }

    .sortby__options {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      // width: 25%;
      padding: 0 12px;
      height: 100%;
    }

    .size__option {
      background: #ffffff00;
      color: #000;
      padding: 3px 7px;
      border-radius: 3px;
      transition: all 0.3s ease-in;

      &:hover,
      &.selected {
        background: #eb621d;
        color: #fff;
      }
    }

    input[type="checkbox"] {
      appearance: none;
      display: none;
    }
  }

  .filters-buttons {
    display: none;
    // display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    margin-block-start: auto;
    opacity: 0;
    transition: all 0.9s ease-in-out;

    .secondary-button {
      width: 100%;
      border: 1px solid #000;
    }
    .secondary-button,
    .primary-button {
      height: 50px;
    }
    a {
      font-size: 16px;
    }
  }
}

// HEBREW
.he {
  .filters-container {
    direction: rtl;
  }
}

/* SCROLLBAR  */
.filters-container.show::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.filters-container.show::-webkit-scrollbar-track {
  background-color: #000;
}

.filters-container.show::-webkit-scrollbar-thumb {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgb(255, 255, 255) 40%,
    transparent 100%,
    rgb(255, 255, 255) 60%,
    transparent
  );
  border-radius: 10px;
}
/* /////////////////////////// */

//RESPONSIVE
@media (max-width: 768px) {
  .filters-container {
    width: 90%;
    height: 70px;
    // height: 9dvh;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    // commented out because avoid the sticky effects
    // position: fixed;
    // bottom: 3vh;
    // left: 5%;
    position: unset;
    transition: width 0.8s
        cubic-bezier(0.645, 0.045, 0.355, 1),
      height 2s cubic-bezier(0.645, 0.045, 0.355, 1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.show {
      width: 95%;
      // height: 650px;
      height: 75dvh;
      overflow-y: scroll;
      left: 2.5%;
    }

    .filters-header {
      height: 70px;
      padding: 30px 20px;

      span {
        font-size: 12px;
      }
    }

    .filters-options {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;
      padding: 30px 15px;
      height: max-content;
      opacity: 0;
      transition: all 0.9s ease-in-out;
      // height: 350px;

      h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        width: 100%;
        padding: 20px 0;
        font-size: 12px;
        margin-bottom: 0px;
        border-bottom: 1px solid #f0f0f0;

        .icon {
          display: block;
        }
      }

      label,
      span {
        font-size: 12px;
      }

      .colors__options,
      .sizes__options,
      .categories__options {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        padding: 0 12px;
        height: auto;
        width: 100%;
      }
    }

    .filters-buttons {
      gap: 10px;
      padding: 10px;

      .secondary-button,
      .primary-button {
        height: 40px;
      }
      a {
        font-size: 12px;
        padding: 0 5px;
      }
    }
  }
  .options-list {
    transition: all 0.4s ease-in !important;
    max-height: 0;
    overflow: hidden;
    gap: 20px !important;

    &.show {
      transition: all 1.2s ease-out !important;
      max-height: 500px;
    }
  }
}
.fake-container {
  display: flex;
  align-items: end;
  // height: 100dvh;
  position: sticky;
  bottom: 3dvh;
}
