.mobile-menu-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 11dvh;
  // height: 85px;
  // height: 135px;
  overflow: hidden;
  background-color: #fff;
  z-index: 99999;
  // padding: 10px 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  transition: height 0.5s ease-out;

  &.open {
    height: 100dvh;
    overflow-y: scroll;
    transition: height 0.5s ease-out;
  }

  .mobile-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px;

    .header-logo {
      width: 130px;
      margin-right: -15px;
      opacity: 1;
      transition: all 1s ease-in;

      &.hidden {
        transition: all 0.3s ease-out;
        opacity: 0;
      }
    }

    .mobile-menu-icons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      width: 70px;

      svg {
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: #000;
      }
    }
  }

  .customer-mobile-menu {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0;
    font-size: 8px;
    letter-spacing: 1px;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px 5px;
      padding: 5px;
      height: 50px;
      width: 100%;
      border-radius: 8px 8px 0 0;
      background: #fff;
      color: #000;
      transition: all 0.3s ease-out;

      svg {
        width: 12px;
        height: 12px;
      }
    }
    .active {
      background: #f0f0f0;
      transition: all 0.3s ease-out;
    }
  }

  .mobile-menu-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    gap: 30px;
    width: 100%;
    padding: 30px 0 0 0;

    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: all 0.3s ease-out;
    a {
      transition: all 0.3s ease-out;
      color: #fff;
    }

    &.rendered {
      opacity: 1;
      visibility: visible;
      // height: 100%;
      transition: all 2s ease-out;

      a {
        transition: all 2s ease-out;
        color: #000;
      }
    }

    .user-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;
      width: 100%;
      margin-top: 50px;

      .login-button,
      .user-container,
      .wishlist-header {
        background: #000;
        padding: 5px 0px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        width: 80% !important;

        a,
        span {
          color: #fff;
          display: block;
          width: 100% !important;
        }
      }
    }

    .sidemenu-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      // height: 70%;
      height: auto;
      padding: 0;
      position: unset;
      opacity: 1;
      margin-bottom: 30px;

      li {
        font-size: 14px;
        font-weight: 500;

        ul li {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .mobile-menu-container {
    // padding: 10px 20px;
    display: block;

    .search-icon {
      width: 200px;
      position: relative;

      svg {
        background: #fff;
        position: absolute;
        top: -13px;
        right: 0px;
        transition: all 0.4s ease;
        border-radius: 50px;
      }

      input {
        background-color: #fff;
        border-radius: 5px;
        font-size: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        height: 40px;
        width: 0%;
        border: none;
        outline: none;
        box-sizing: border-box;
        transition: all 0.7s ease-in-out;
        position: absolute;
        top: -23px;
        right: -2px;
      }
      &.active {
        input {
          padding: 0 20px;
          height: 40px;
          width: 65vw !important;
          background: #eeeeee !important;
        }
        svg {
          background: #eeeeee !important;
        }
      }
    }

    .search-bar-container {
      width: 25px !important;
      justify-content: flex-start !important;
      position: fixed;
      bottom: 10px;
      left: 50px;

      .language-submenu {
        top: -80px;
        // left: -8px;
      }

      .search-bar-input {
        display: none;
      }
    }
  }
}
