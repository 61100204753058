@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap");

/* Path: src/components/general/style.scss */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Palanquin", sans-serif;
  -webkit-tap-highlight-color: transparent;
}
body {
  background: white;
  overflow-x: hidden;
}

/* SCROLLBAR  */
body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-track {
  background-color: #000;
  /* background-color: #fff; */
}

body::-webkit-scrollbar-thumb {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgb(255, 255, 255) 40%,
    transparent 100%,
    rgb(255, 255, 255) 60%,
    transparent
      /* rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 40%,
    transparent 100%,
    rgba(0, 0, 0, 1) 60%,
    transparent */
  );
  border-radius: 10px;
}
/* /////////////////////////// */

/* HEBREW  */
.he * {
  font-family: "Assistant", sans-serif;
  letter-spacing: 2px;
}
.he input[type="tel"] {
  direction: rtl !important;
}

a {
  text-decoration: none;
}
ul {
  list-style: none;
}
input {
  transition: all 0.3s ease-in;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  width: auto;
  filter: sepia(0%) brightness(100%) hue-rotate(300deg)
    saturate(99%) contrast(300%);
}
input[type="checkbox"]:checked {
  filter: sepia(80%) brightness(100%) hue-rotate(300deg)
    saturate(99%) contrast(300%);
  accent-color: #0080ff;
}

textarea {
  resize: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.error,
#error {
  border: red solid 1px !important;
}

.errorLabel {
  color: red !important;
  font-weight: bolder !important;
}

.mobile-only {
  display: none !important;
}

/* MEDIA QUERIES */
@media (max-width: 768px) {
  .mobile-only {
    display: block !important;
  }
  .desktop-only {
    display: none !important;
  }
}
