.order-summary-container,
.order-discount-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .pricing-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    span {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.8px;
      text-transform: uppercase;
    }
    .amount {
      font-weight: 500;
      color: #2b7f08;
    }
  }
  .total-price {
    span {
      font-weight: 700 !important;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-size: 18px;
    }
    .amount {
      color: #eb621d;
    }
  }

  .discount-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 5px;

    button {
      width: 50%;
      height: 40px;
      background: #f4f4f4;
      border: 1px solid #cfcece;
      border-radius: 3px;
      outline: none;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.2px;
      color: #cfcece;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: #cfcece;
        color: #000;
      }
    }

    #points,
    #giftCard {
      width: 100%;
      div {
        width: 100%;
      }
      input {
        height: 40px;
        width: 100%;
        border: 1px solid #cfcece;
        background: #f4f4f4;
        outline: none;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.2px;
      }
    }
  }
}

// ADS BANNER
.right-checkout {
  .ads-banner-container {
    margin-bottom: 0px;
    padding: 0;
    .ads-banner {
      height: fit-content;
      background-size: 200%;
      background-position-x: 10vw;
      background-position-y: center;
      gap: 5px;
      transition: all 0.3s ease-in-out;
      scale: 1;

      &:hover {
        scale: 1.05;
        background-position-x: -2vw;
      }
    }
    h3 {
      font-size: 26px;
      font-weight: 400;
      line-height: 1.2em;
    }
    h6 {
      font-size: 16px;
      font-weight: 300;
    }
    span {
      div {
        font-size: 12px;
        letter-spacing: 1.5px;
      }
    }
    .btn-42::after {
      background: #000;
    }
  }
}

// HEBREW
.he {
  .right-checkout {
    direction: rtl;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .order-summary-container,
  .order-discount-container {
    gap: 30px;
    width: 100%;
    padding: 30px 25px;

    h2 {
      font-size: 20px;
      letter-spacing: 1.8px;
    }

    .pricing-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-top: 10px;

      span {
        font-size: 14px;
        letter-spacing: 1.5px;
      }
    }
    .total-price {
      span {
        letter-spacing: 1.8px;
      }
    }

    // .discount-row {
    // button {
    //   width: 50%;
    //   height: 40px;
    //   font-size: 12px;
    //   letter-spacing: 1.2px;
    // }

    // #points,
    // #giftCard {
    // width: 100%;
    // div {
    //   width: 100%;
    // }
    // input {
    //   height: 40px;
    //   width: 100%;
    //   font-size: 12px;
    //   letter-spacing: 1.2px;
    // }
    // }
    // }
  }

  // ADS BANNER
  .right-checkout {
    .ads-banner-container {
      .ads-banner {
        height: fit-content;
        background-size: 200%;
        background-position-x: 0vw;

        &:hover {
          scale: 1.05;
          background-position-x: -3vw;
        }
      }
      h3 {
        font-size: 20px;
      }
      h6 {
        font-size: 12px;
      }
      span {
        div {
          font-size: 12px;
          letter-spacing: 1.2px;
        }
      }
    }
  }

  // HEBREW
  // .he {
  //   .checkout-review-container {
  //     direction: unset;
  //   }
  // }
}
