.search-bar-container {
  // width: 33% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start !important;
  gap: 15px;

  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1.8px;
  transition: all 0.3s ease-in;

  .search-bar-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  input {
    width: 150px;
    height: fit-content;
    border: none;
    border-bottom: 1px solid #b8b8b8;
    background: transparent;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    color: #515151;
    transition: all 0.3s ease-in;

    &:focus {
      outline: none;
    }
  }

  .language-icon {
    position: relative;

    svg {
      cursor: pointer;
    }
  }
  .language-submenu {
    position: absolute;
    top: 30px;
    left: -10px;
    width: fit-content;
    height: fit-content;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease-in-out;
    padding: 10px;
    opacity: 0;
    // display: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    &.open {
      z-index: 9999;
      opacity: 1;
    }

    span {
      cursor: pointer;
      scale: 1;
      transition: all 0.2s ease-in-out;
      &:hover {
        scale: 1.1;
      }
    }
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .search-bar-container {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center !important;
    gap: 20px;

    font-size: 20px !important;
    letter-spacing: 1.5px;
    margin-left: -35px; /* icon font-size + gap   */

    input {
      width: 100%;
      height: fit-content;
      font-size: 15px !important;
      letter-spacing: 1.8px;
    }

    .language-submenu {
      position: absolute;
      top: 30px;
      left: -10px;
      padding: 10px;
      opacity: 0;
      gap: 5px;
      z-index: 0;

      &.open {
        width: fit-content;
        height: fit-content;
        opacity: 1;
        z-index: 9999;
        font-size: 14px;
      }
    }
  }
}
