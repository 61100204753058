.customer-wishlist-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  row-gap: 35px;
  column-gap: 20px;
  flex-wrap: wrap;

  a {
    opacity: 1;
    &:hover {
      font-weight: 400;
    }
  }
  .primary-button {
    width: 260px;
    margin: inherit;

    a {
      color: #fff !important;
      opacity: 1;
      text-transform: uppercase;
    }
  }
  .btn-42:hover {
    a {
      color: #000 !important;
    }
  }

  .wishlist-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    margin-bottom: 15px;
    background: linear-gradient(#ffffff, #ffffff);

    width: 260px;
    min-width: 260px;
    max-width: 260px;

    overflow: hidden;
    text-transform: uppercase;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    div {
      padding: 20px;
      width: 100%;
    }

    img {
      width: 260px;
      min-width: 260px;
      max-width: 260px;

      height: 330px;
      min-height: 330px;
      max-height: 330px;
      object-fit: cover;
      object-position: center;
      // opacity: 0.4;
    }
    h3 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2.1px;
    }
  }

  .prices {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 !important;
    margin-top: 20px;

    span {
      font-size: 13px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      letter-spacing: 1.8px;
      color: #818181;
    }
    .original-price {
      font-style: italic;
      font-size: 13px;
      color: #ce5b5b;
      text-decoration: line-through;
    }
  }

  .wishlist-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 7px;

    span {
      color: #000;
      cursor: pointer;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-wishlist-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    .primary-button {
      width: 100%;
    }

    .wishlist-card-container {
      width: 100%;
      min-width: 0;
      max-width: 100%;

      img {
        width: 270px;
        min-width: 270px;
        max-width: 270px;

        height: 370px;
        min-height: 370px;
        max-height: 370px;
      }
      h3 {
        font-size: 12px;
        letter-spacing: 1.8px;
      }
    }

    .prices {
      span {
        font-size: 13px;
        letter-spacing: 1.5px;
      }
      .original-price {
        font-size: 13px;
      }
    }

    .wishlist-buttons {
      font-size: 12px;
      letter-spacing: 1.8px;
      margin-top: 7px;
    }
  }
}
