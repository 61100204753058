.customer-profile-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
  margin: auto;

  .profile-edit {
    display: flex;
    width: 660px;
    // width: 750px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    opacity: 0.9;
    background: #fbfbfb;
    border-radius: 3px;
    text-transform: uppercase;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    h3 {
      color: #515151;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    .profile-edit-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      width: 100%;

      .two-fields-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 15px;
        width: 100%;
        div {
          width: 100%;
        }
      }

      .not-specified {
        span {
          color: #b8b8b8;
          font-size: 13px;
          font-weight: 400;
          font-style: italic;
        }
        width: 100%;
        border-radius: 3px;
        padding: 10px;
      }
    }
    input,
    span {
      color: #515151;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      width: 100%;

      &:disabled {
        border: none;
        outline: none;
        background: none;
      }

      &:focus {
        outline: none;
      }
    }
    button {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2.1px;
      outline: none;
      border: none;
      background: none;
      align-self: flex-end;
      cursor: pointer;
    }
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.9px;
      width: 100%;

      input {
        width: 100%;
        border: 0.75px solid #b8b8b8;
        border-radius: 3px;
        padding: 10px;
      }
    }
    .primary-button a {
      color: #fff;
      opacity: 1;
    }

    .btn-42:hover {
      a {
        color: #000;
      }
    }
  }
  .loader-container {
    height: 100%;
    width: 100%;
    min-height: 270px;

    span {
      width: auto;
    }
  }
}

.he .customer-profile-content {
  input {
    // text-align: right;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-profile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    width: 100%;

    .fade-in-up {
      width: 100% !important;
    }

    .profile-edit {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
      h3 {
        font-size: 18px;
        letter-spacing: 2.5px;
      }
      .profile-edit-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;

        .two-fields-row {
          display: flex;
          flex-direction: column;
        }

        .not-specified {
          span {
            font-size: 12px;
          }
        }
      }
      input,
      span {
        font-size: 12px;
        letter-spacing: 1.5px;
      }

      button {
        font-size: 12px;
        letter-spacing: 2px;
        align-self: flex-end;
      }
      label {
        font-size: 12px;
        letter-spacing: 1.8px;
      }
    }
    //LOADER
    .loader-container {
      height: 100%;
      width: 100%;
      min-height: 70vh;
    }
  }
}
