.checkout-review-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .item-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
    }

    .item-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 20px;
      h4,
      h5 {
        font-weight: 700;
        color: #515151;
        font-size: 14px;
        letter-spacing: 2px;
      }

      .original-price {
        font-style: italic;
        font-size: 13px;
        color: #ce5b5b;
        text-decoration: line-through;
      }
    }

    img {
      height: 120px;
      max-height: 120px;
      min-height: 120px;
      max-width: 90px;
      min-width: 90px;
      width: 90px;
      object-fit: contain;
      border-radius: 3px;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
    }
    .item-details {
      display: flex;
      flex-direction: column;
      gap: 0px;
      // margin: 12px 0;

      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.7px;
    }
    .delete {
      color: lightgrey;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 2.2px;
      cursor: pointer;

      transition: all 0.2s ease-in-out;

      &:hover {
        color: grey;
      }
    }
  }
  .amount {
    font-size: 16px;
    font-weight: 700;
    color: #2b7f08;
    letter-spacing: 1.2px;
  }
  .total {
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-transform: uppercase;
  }
  .pricing {
    display: flex;
    flex-direction: column;
    width: 100%;

    .pricing-row {
      &:nth-child(2),
      &:nth-child(3) {
        span {
          font-size: 12px;
          font-weight: 200;
        }
      }
    }
  }

  .pricing-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    span {
      color: #515151;
      font-size: 14px;
      letter-spacing: 2px;

      &:first-child {
        font-weight: 400;
      }
      &:last-child {
        font-weight: 700;
      }
    }
  }
  .promo-code,
  .member-status {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    color: green;
    font-size: 14px;
    letter-spacing: 2px;

    .delete {
      color: lightgrey;
      cursor: pointer;

      transition: all 0.2s ease-in-out;

      &:hover {
        color: grey;
      }
    }
  }

  .total-price {
    .pricing-row {
      span {
        font-weight: 700;
      }
    }
  }
}

// HEBREW
.he {
  .checkout-review-container {
    direction: rtl;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .checkout-review-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 30px 20px;

    h2 {
      font-size: 20px;
      letter-spacing: 1.8px;
    }

    .item-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      padding: 20px 30px;

      .item-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 20px;
        h4,
        h5 {
          font-size: 14px;
          letter-spacing: 1.8px;
        }

        .original-price {
          font-size: 12px;
        }
      }

      img {
        height: 120px;
        max-height: 120px;
        min-height: 120px;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
      }
      h4 {
        font-size: 14px;
        letter-spacing: 2px;
      }
      .item-details {
        display: flex;
        flex-direction: column;
        gap: 0px;

        font-size: 10px;
        letter-spacing: 1.5px;

        &:nth-child(1) {
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
      // .delete {
      //   font-size: 10px;
      //   letter-spacing: 2.2px;
      // }
    }
    .amount {
      font-size: 16px;
      letter-spacing: 1.2px;
    }
    .total {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .pricing {
      display: flex;
      flex-direction: row;
      width: 100%;

      .pricing-row {
        &:nth-child(2),
        &:nth-child(3) {
          span {
            font-size: 12px;
          }
        }
      }
    }

    .pricing-row {
      span {
        font-size: 14px;
        letter-spacing: 1.8px;
      }
    }
    .promo-code,
    .member-status {
      font-size: 14px;
      letter-spacing: 1.8px;
    }
  }
}
