.secondary-button {
  background: white;
  display: flex;
  height: 60px;
  width: fit-content;
  margin: auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  outline: none !important;
  position: relative;

  a,
  div {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;

    transition: all 0.3s ease;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    padding: 0px 40px;
  }

  &.disabled {
    background: #d4d4d4;
    border: 2px solid #d4d4d4;
    cursor: not-allowed;

    &:hover {
      background: #d4d4d4;
      border: 2px solid #d4d4d4;
    }
    &:after {
      background: #d4d4d4;
    }

    a,
    div {
      color: #ababab !important;
      pointer-events: none;
    }
  }
}

// HOVER EFFECTS
.btn-41 {
  // border: 2px solid rgb(255, 255, 255);
  color: #fff !important;
  z-index: 1;
}

.btn-41:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background: #000;
  transition: all 0.3s ease;
}

.btn-41:hover {
  a,
  div {
    color: #fff;
    opacity: 1;
    font-weight: 400;
  }
}

.btn-41:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

// RESPONSIVE
@media (max-width: 768px) {
  .secondary-button {
    height: 40px;
    gap: 10px;
    transition: all 0.2s ease;
    outline: none !important;
    a,
    div {
      font-size: 14px;
      transition: all 0.2s ease;
      padding: 0px 30px;
    }
  }
  // HOVER EFFECTS
  .btn-41:after {
    transition: all 0s ease;
  }
}
