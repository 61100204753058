.product-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 55%;

  // .main-image {
  //   // width: 650px;
  //   // max-width: 650px;
  //   // min-width: 650px;
  //   // height: 400px;
  //   // max-height: 400px;
  //   // min-height: 400px;
  //   // padding: 25px;
  //   width: 750px;
  //   max-width: 750px;
  //   min-width: 750px;
  //   height: 500px;
  //   max-height: 500px;
  //   min-height: 500px;
  //   padding: 25px;
  //   text-align: center;

  //   img {
  //     // width: 350px;
  //     // max-width: 350px;
  //     // min-width: 350px;
  //     // height: 400px;
  //     // max-height: 400px;
  //     // min-height: 400px;
  //     width: 450px;
  //     max-width: 450px;
  //     min-width: 450px;
  //     height: 500px;
  //     max-height: 500px;
  //     min-height: 500px;
  //     object-fit: cover;
  //   }
  // }

  .other-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    height: 100%;
    padding: 25px;
    margin-top: 30px;
    text-align: center;

    img {
      width: 450px;
      max-width: 450px;
      min-width: 450px;
      height: 500px;
      max-height: 500px;
      min-height: 500px;
      // object-fit: cover;
      object-fit: contain;
    }
  }
  .gallery-slide {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    div {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      img {
        width: 60%;
        height: 60%;
        object-fit: cover;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .mobile-gallery {
    display: none;
  }
}

/* FADE IN UP */
.fade-in-up {
  opacity: 0;
  /* transform: translateY(10px); */
  transform: translateY(20px);
  transition: opacity 1.5s, transform 2.5s ease;
  width: 100%;
}
.fade-in-up-active {
  opacity: 1;
  transform: translateY(0);
}

@media screen and (max-width: 520px) {
  .fade-in-up {
    transform: none;
    opacity: 1;
  }
  .fade-in-up-active {
    transform: none;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .product-gallery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;

    // .main-image {
    //   display: none;
    // }

    .other-images {
      display: none;
    }

    .mobile-gallery {
      display: flex;
      width: 100%;

      img {
        width: 100%;
        max-width: auto;
        min-width: auto;
        height: 40vh;
        max-height: 40vh;
        // height: 400px;
        // max-height: 400px;
        min-height: auto;
        object-fit: cover;
      }

      .swiper-slide {
        width: 100% !important;
        margin: 0;

        img {
          width: 100%;
        }
      }
    }
  }
}
