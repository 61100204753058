.customer-wishlist-content {
  width: 850px;
  min-width: 850px;
  max-width: 850px;
  margin: auto;

  .no-wishlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 100px auto 0 auto;

    text-transform: uppercase;
    text-align: center;

    h3 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 3px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #515151;
      opacity: 0.5;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-wishlist-content {
    width: 100%;
    min-width: 0;
    max-width: 100%;

    .no-wishlist {
      gap: 20px;

      h3 {
        font-size: 18px;
        letter-spacing: 1.8px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1.2px;
      }
    }
  }
}
