.customer-purchases-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.purchase-card-container {
  display: flex;
  width: 660px;
  // width: 100%;
  padding: 24px;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
  }

  .purchase-card-content {
    width: 100%;
  }
  img {
    height: 130px;
    max-height: 130px;
    min-height: 130px;
    max-width: 100px;
    min-width: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 3px;
    margin-right: 25px;
  }
  h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
  }
  h6 {
    color: #676767;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 2px;
  }
  .purchase-card-header {
    margin-bottom: 30px;
  }
  .purchase-card-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;

    span {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 2px;
      width: 100%;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      // gap: 100px;

      .purchase-card-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        gap: 10px;
        width: 100%;

        .details-button {
          display: flex;
          padding: 5px 10px;
          justify-content: center;
          align-items: center;
          text-align: center;
          border: 0.5px solid rgba(0, 0, 0, 0.5);
          border-radius: 3px;
          gap: 5px;
          color: rgba(0, 0, 0, 0.5);
          font-size: 10px;
          font-weight: 400;
          transition: all 0.3s ease;
          letter-spacing: normal;
          width: 50%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.1);
          }

          &.confirm {
            color: #00b300;
            border: 0.5px solid #00b300;

            &:hover {
              background-color: rgba(0, 179, 0, 0.1);
            }
          }
        }
      }
    }
  }
  .green {
    color: #00b300;
  }
  .red {
    color: #ff0000;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-purchases-content {
    gap: 20px;
  }
  .purchase-card-container {
    display: flex;
    width: 100%;
    padding: 15px 20px;
    align-items: center;
    gap: 10px;

    .purchase-card-content {
      width: 100%;
    }
    img {
      height: 120px;
      max-height: 120px;
      min-height: 120px;
      max-width: 80px;
      min-width: 80px;
      width: 80px;
      margin-right: 20px;
    }
    h3 {
      font-size: 10px;
      letter-spacing: 1.2px;
      line-height: 1.5em;
    }
    h6 {
      font-size: 8px;
      letter-spacing: 1.2px;
    }
    .purchase-card-header {
      margin-bottom: 15px;
    }
    .purchase-card-footer {
      span {
        font-size: 10px;
        letter-spacing: 1.2px;
        width: 100%;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .purchase-card-buttons {
          justify-content: end;
          gap: 5px;
          margin-top: 10px;

          .details-button {
            display: flex;
            padding: 3px 5px;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 8px;
            width: 100%;
          }
        }
      }
    }
  }
}
