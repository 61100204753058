.private-member-content {
  width: 850px;
  min-width: 850px;
  max-width: 850px;
  margin: auto;

  .primary-square {
    width: 100%;
    display: flex;
    padding: 24px;
    flex-direction: column;
    background: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/dede6764-451b-467b-bd85-8a920d26f300/public");
    // background: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/3a2c3c2b-67b2-41c0-03e0-f0b321bef900/public");
    background-size: 150%;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 0;
    border-radius: 10px;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      color: #fff;
      text-transform: uppercase;
      font-size: 24px;
      letter-spacing: 3.6px;
      text-align: left;

      h5 {
        font-weight: 600;
      }
      h6 {
        font-weight: 400;
      }
      span {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 2.7px;
      }
      .points_count {
        margin-top: 80px;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 2px;

        span {
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
  }

  .private_sale {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 890px;
    padding: 35px 25px;
    margin: 24px 0;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;

    h3 {
      font-size: 17px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 2.4px;

      span {
        font-weight: 700;
        display: inline-block;
      }
    }

    span {
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 3.4px;
      display: flex;
      align-items: center;
    }
  }

  .secondary-squares {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
    color: #fff;
    text-transform: uppercase;

    a {
      width: 100%;
    }
    div:nth-child(1) {
      background: #eb621d;
    }

    .secondary-square {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 180px;
      width: 270px;
      padding: 25px 20px;
      gap: 12px;
      border-radius: 3px;
      h4 {
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 2px;
      }
      h5 {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 2px;
      }
      h6 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 2px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 1.8px;
      }
      span {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 2.6px;
      }

      &.explore {
        align-items: center;
        scale: 1;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        background: rgba(255, 255, 255, 0.7);
        color: #000;
        width: 100%;
        img {
          width: 100px;
          text-align: center;
        }
        &:hover {
          scale: 1.02;
        }
      }
    }
  }

  .fidelity-privileges {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 20px;
    margin-top: 20px;
    width: 100%;

    .privilege {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      width: 100%;
      // height: 220px;
      padding: 20px 10px;
      background: #ffffff20;
      border-radius: 3px;
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(4px);

      img {
        width: 35px;
        height: 35px;
      }

      p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}

.he .private-member-content {
  direction: ltr;
}

//RESPONSIVE
@media (max-width: 900px) {
  .private-member-content {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    margin: auto;

    .primary-square {
      width: 100%;
      padding: 20px;
      background: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/dede6764-451b-467b-bd85-8a920d26f300/public");
      background-size: 150%;
      background-repeat: no-repeat;
      background-color: #000;
      background-position-y: 60%;
      background-position-x: -30%;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        font-size: 25px;
        letter-spacing: 2px;
        text-align: center;

        span {
          font-size: 14px;
          letter-spacing: 2px;
        }
        .points_count {
          margin-top: 50px;
          font-size: 12px;
          letter-spacing: 1.8px;

          span {
            font-size: 12px;
          }
        }
      }
    }

    .private_sale {
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      width: 100%;
      padding: 35px 25px;
      margin: 24px 0;

      h3 {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 2px;
      }
    }

    .secondary-squares {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      margin-top: 20px;

      .secondary-square {
        align-items: center;
        height: auto;
        min-height: 135px;
        width: 100%;
        padding: 20px;
        gap: 12px;

        h4 {
          font-size: 22px;
          letter-spacing: 1.5px;
        }
        h5 {
          font-size: 16px;
          letter-spacing: 1.5px;
        }
        h6 {
          font-size: 14px;
          letter-spacing: 1.5px;
        }
        p {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          letter-spacing: 1.2px;
        }
        span {
          display: flex;
          align-items: center;
          font-size: 12px;
          letter-spacing: 2.4px;
        }

        &.explore {
          align-items: center;
          width: 100%;
          img {
            width: 80px;
          }
        }
      }
    }

    .fidelity-privileges {
      flex-direction: column;
      width: 90%;
      margin: 50px auto 0 auto;
      gap: 10px;
      .privilege {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
        height: auto;
        padding: 20px;

        p {
          font-size: 12px;
          letter-spacing: 1.5px;
        }
      }
    }
  }
}
