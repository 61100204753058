.button-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.button-bar-button {
  font-weight: 500;
  font-size: 16px;
  color: #000000;

  padding: 15px 30px;
  gap: 10px;

  border: 1px solid #da5b7a;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  background-color: transparent;

  transition: all 0.4s ease-in-out;

  span {
    font-weight: 400;
    font-size: 14px;
  }
}
.button-bar-button.selected,
.button-bar-button:hover {
  background-color: #da5b7a !important;
  color: #fff;
}
.button-bar-button.selected.secondary,
.button-bar-button.secondary:hover {
  background-color: #db6c88 !important;
  color: #fff;
}

.button-bar-button.secondary {
  background-color: #f6e1e6;
  padding: 15px 30px;
}
.button-bar-subtitle {
  font-size: 16px;
  font-weight: 300;
  margin-top: 150px !important;
}

@media screen and (max-width: 768px) {
  .button-bar {
    flex-wrap: wrap;
  }

  .button-bar-button {
    white-space: nowrap;
  }
}

.button-bar-input {
  display: none;
}
