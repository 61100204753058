.logo-body {
  height: 100vh;
  width: 100vw;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  text-align: center;
  width: 100%;
}

.logo {
  width: 20%;
  height: 100%;
  object-fit: cover;
  animation: opacity 4s linear infinite;
}

@keyframes opacity {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .logo-body {
    position: unset;
  }
  .logo {
    width: 50%;
  }
}
