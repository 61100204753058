.home-page-container {
  // padding: 60px 35px;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.4s ease-in-out;

  .home-content {
    display: flex;

    .right-part {
      width: 100%;
    }
  }
  .sidemenu-container {
    padding: 50px 0 0 50px;
    width: 20vw;
    // height: 2300px;
  }

  // WHATSAPP BUTTON
  .whatsapp-button {
    position: sticky;
    z-index: 99999;
    cursor: pointer;

    bottom: 3vh;
    left: 97vw;
    width: 40px;
    height: 40px;
    background-color: #25d366;
    text-align: center;
    align-content: center;
    // padding: 5px;
    color: white;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;

    &:hover {
      background-color: #1ebe57;
      transform: scale(1.1);
    }

    &:active {
      background-color: #128c7e;
    }

    svg {
      font-size: 25px;
      margin-top: 7px;
    }
  }

  .navlink-style {
    text-decoration: none;
    color: inherit;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .home-page-container {
    padding: 0px 0px;
    .home-content {
      flex-direction: column;
      gap: 20px;
      .right-part {
        width: 100%;
      }
    }
    .sidemenu-container {
      display: none;
    }
  }
}
