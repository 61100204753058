.hero-home {
  height: 90vh;
  // margin-top: -15vh;
  width: 100vw;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/41e72adb-4d40-4e7b-e7a3-7951bb267b00/public");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 80px 50px 30px 50px;

  .hero-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    color: #fff;
    text-transform: uppercase;

    h1 {
      color: #fff;
      text-align: center;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-size: 36px;
      font-weight: 500;
      letter-spacing: 20px;
      line-height: 1em;
    }
    h2 {
      color: #fff;
      text-align: center;
      font-size: 125px;
      font-weight: 600;
      line-height: 1.2em;
      letter-spacing: 5px;
      text-transform: uppercase;
    }
  }

  .sidemenu-container {
    display: none;
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .hero-home {
    padding: 80px 20px 30px 20px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/41e72adb-4d40-4e7b-e7a3-7951bb267b00/public");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    .sidemenu-container {
      display: none !important;
    }

    .hero-content {
      h1 {
        font-size: 24px;
        letter-spacing: 8px;
      }
      h2 {
        font-size: 50px;
        letter-spacing: 5px;
      }
    }
  }
}
