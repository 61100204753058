.checkout-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 10px 40px;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  img {
    width: 50%;
  }

  a {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);

    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      color: rgba(0, 0, 0, 1);
    }

    &:visited,
    &:active {
      color: rgba(0, 0, 0, 0.8);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .checkout-header {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 20px;

    img {
      width: 40%;
    }
    a {
      font-size: 12px;
      letter-spacing: 1.2px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
