.customer-layout-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100vw;
  padding: 60px 120px;
  // gap: 200px;
  background: linear-gradient(
      180deg,
      rgba(211, 211, 211, 0.9) 10%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/e67923d3-60bd-44e1-ff64-86eb3fef8f00/public");
  // background-size: cover;
  // background-repeat: no-repeat;
  background-size: 100%;
  background-repeat: repeat-y;
  transition: all 0.4s ease-in;

  .customer-panel-sidemenu {
    a {
      color: #000;
      opacity: 0.5;

      &:hover {
        font-weight: 600;
      }
    }
  }

  input {
    &.error {
      border: 1px solid red !important;
    }
  }

  .errorLabel {
    color: red !important;
    font-weight: bolder !important;
  }

  .customer-mobile-menu {
    display: none;
  }
}
.he .customer-layout-container {
  direction: rtl;

  input {
    direction: rtl;
    text-align: right;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-layout-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 50px 30px;

    .customer-panel-sidemenu {
      display: none;
    }
  }
}
