.header-container {
  width: 100vw;
  height: 15dvh;
  padding: 0px 30px;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-transform: uppercase;

  position: sticky;
  top: 0;
  z-index: 999999;
  transition: all 0.3s ease;

  &.customer-panel {
    &.smaller-header {
      .left-part {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }

      .header-logo img {
        width: 65%;
      }
    }
  }

  .header-logo {
    transition: all 0.3s ease;
    text-align: center;
    margin-top: 5px;
  }

  .hight-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .right-part {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 30px;

      .signin-buttons {
        display: flex;
        flex-direction: row;
        gap: 30px;
      }

      li {
        cursor: pointer;
      }

      span {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.8);

        a {
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          font-weight: 400;
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            color: #000;
          }
        }
      }

      .cart-icon,
      .wishlist-header {
        transition: all 0.3s ease;
        cursor: pointer;
      }
      .login-button {
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
          color: #000;
        }
      }
    }
    svg {
      width: 20px !important;
      height: 20px !important;
    }
  }

  //main-menu on customer panel
  .header-main-menu {
    opacity: 1;
    transition: all 0.3s ease;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
      gap: 40px;

      color: rgba(0, 0, 0, 0.5);
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 2.2px;

      a {
        color: rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease;
        border-bottom: 1px solid rgba(255, 0, 0, 0);

        &:hover {
          transition: all 0.3s ease;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  // SMALLER HEADER
  &.smaller-header {
    height: 11dvh;

    .hight-part {
      svg {
        width: 15px !important;
        height: 15px !important;
      }
    }

    .header-logo img {
      width: 72%;
    }

    .right-part {
      span a,
      span {
        font-size: 11px;
      }
    }
  }
}

.user-container {
  display: flex;
  position: relative;
  width: fit-content !important;
  .userName {
    cursor: pointer;
  }
}

//HEBREW
.he {
  .header-container {
    direction: rtl;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .header-container {
    width: 100vw;
    height: auto;
    top: 0;
    z-index: 999;

    .hight-part {
      display: none;
    }
    &.smaller-header {
      position: unset;
      height: 11dvh !important;

      .header-main-menu {
        display: none;
      }
    }

    &.customer-panel {
      &.smaller-header {
        height: 15dvh !important ;

        .mobile-menu-container {
          height: 130px;

          &.open {
            height: 100dvh;
            overflow-y: scroll;
            transition: height 0.5s ease-out;
          }
        }
      }
    }
  }
}
