.customer-panel-sidemenu {
  display: flex;
  min-width: 350px;
  max-width: 350px;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 36px;

  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2.1px;
    transition: all 0.2s ease;

    &:hover {
      font-weight: 600;
      font-size: 15px;
    }

    &.active {
      // color: #fff;
      font-weight: 700;
      opacity: 1;
      font-size: 15px;

      &:hover {
        font-weight: 700;
      }
    }
  }
  .active-link {
    font-size: 15px;
    font-weight: 700;
  }
  .logout-button {
    color: #000;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2.2px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      color: #000;
      font-size: 15px;
      font-weight: 600;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-panel-sidemenu {
    display: flex;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    a {
      text-decoration: none;
      font-size: 13px;
      letter-spacing: 1.8px;
      color: #000;

      &:hover {
        font-weight: 600;
        font-size: 14px;
      }

      &.active {
        opacity: 1;
        font-size: 14px;
      }
    }
    .active-link {
      font-size: 14px;
      font-weight: 900;
    }
    .logout-button {
      opacity: 0.5;
      font-size: 13px;
      letter-spacing: 1.8px;

      &:hover {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
