.sidemenu-container {
  transition: all 0.4s ease-out;
  width: fit-content;
  // height: 100%;
  padding: 50px 0 0 30px;
  z-index: 999;

  position: sticky;
  top: 15vh;
  left: 0vw;
  opacity: 1;

  &.hidden {
    opacity: 0;
  }

  .sidemenu {
    transition: all 0.4s ease-out;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    width: 100%;
    width: fit-content;
    position: sticky;
    top: 15vh;
    height: 100vh;

    li {
      cursor: pointer;
      font-weight: 400;
      margin-bottom: 5px;
    }

    .submenu-items {
      // transition: all 0.5s ease-out;
      ul {
        display: flex;
        flex-direction: column;
        padding: 0px 10px;
        transition: all 0.5s ease-out;
        max-height: 0;

        li {
          overflow: hidden;
          opacity: 0;
          transition: all 1s ease-out;
        }
      }

      // &:hover {
      &.show {
        // transition: all 0.5s ease-out;
        ul {
          max-height: 700px;
          transition: all 0.5s ease-out;
          padding: 10px 10px;

          li {
            opacity: 0.5;
          }
        }
      }

      li {
        // margin-bottom: 0px;
        // transition: all 0.4s ease-out;

        &:hover,
        &.selected {
          font-weight: 600;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }

    // .submenu-items {
    //   transition: all 0.4s ease-out;
    //   display: flex;
    //   flex-direction: column;
    //   padding: 0 10px;
    //   height: 0;
    //   // visibility: hidden;
    //   overflow: hidden;
    //   opacity: 0;

    //   &.show {
    //     transition: all 0.4s ease-in;
    //     padding: 10px;
    //     height: 700px;
    //     // visibility: visible;
    //     overflow: visible;
    //     opacity: 0.5;
    //   }

    //   li {
    //     cursor: pointer;
    //     margin-bottom: 0px;
    //     transition: all 0.4s ease-out;

    //     &:hover,
    //     &.selected {
    //       font-weight: 600;
    //       font-size: 16px;
    //       color: rgba(0, 0, 0, 0.9);
    //     }
    //   }
    // }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .sidemenu-container {
    .sidemenu {
      font-size: 14px;
      letter-spacing: 1.5px;
      height: 100%;

      .submenu-items {
        ul {
          padding: 0;
          max-height: 0;
          transition: all 0.5s ease;

          li {
            overflow: hidden;
            opacity: 0;
            transition: all 0.5s ease-out;
          }
        }

        &.show {
          ul {
            max-height: 700px;
            transition: all 1.5s ease-out;
          }
        }
      }

      // .submenu-items {
      //   padding: 0 10px;
      //   max-height: 0;
      //   visibility: hidden;
      //   overflow: hidden;
      //   opacity: 0;
      //   transition: all 0.4s ease-out;

      //   &.show {
      //     transition: all 0.4s ease-out;
      //     padding: 10px;
      //     max-height: 700px;
      //     visibility: visible;
      //     overflow: visible;
      //     opacity: 0.5;
      //   }
      // }
    }
  }
}
