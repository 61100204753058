.single-item {
  color: white;
  // width: 320px;
  // height: 600px;
  width: 330px;
  min-width: 330px;
  max-width: 330px;
  height: 500px;
  max-height: 500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none !important;
  text-transform: uppercase;
  // overflow: hidden;
  position: relative;

  .fill-heart,
  .heart {
    position: absolute;
    z-index: 999;
    bottom: 40px;
    right: 0;
    margin: 10px;
    font-size: 22px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.2s ease;
    color: #000;
  }

  img {
    // padding: 24px;
    // width: 330px;
    // min-width: 330px;
    // max-width: 330px;
    // height: 500px;
    // max-height: 500px;
    // min-height: 500px;
    // object-fit: contain;
    // object-position: top;
  }
  .image-container {
    position: relative;
    overflow: hidden;
    height: 400px;
    min-height: 400px;
    max-height: 400px;
    width: 300px;
    min-width: 300px;
    max-width: 300px;

    img {
      padding: 10px;
      object-fit: contain;
      width: 100%;
      height: 100%;
      // width: 250px;
      // min-width: 250px;
      // max-width: 250px;
      transition: opacity 0.3s ease-in;
      &.secondary {
        opacity: 0;
        margin-top: -400px;
      }
    }

    &:has(.secondary) {
      &:hover .default {
        opacity: 0; /* On hover, hide the main image */
      }
      &:hover .secondary {
        opacity: 1; /* On hover, show the hover image */
      }
    }
  }
  .name-prices {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 50px;
    color: #000;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: #000;

    h3 {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.2px;
      text-decoration: none !important;
      text-align: center;
    }
    span {
      color: #818181;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1.8px;
    }
  }
  .prices {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0;

    span {
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      color: #818181;
      letter-spacing: 1.8px;
    }
    .original-price {
      font-style: italic;
      font-size: 12px;
      color: #ce5b5b;
      text-decoration: line-through;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .single-item {
    width: 330px;
    min-width: 330px;
    max-width: 330px;
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    position: relative;

    .image-container {
      position: relative;
      overflow: hidden;
      height: 300px;
      min-height: 300px;
      max-height: 300px;
      width: 300px;
      min-width: 300px;
      max-width: 300px;

      img {
        padding: 0px;
        object-fit: contain;
        width: 100%;
        height: 100%;
        transition: opacity 0.2s ease-in;

        &.secondary {
          opacity: 0;
          margin-top: -300px;
        }
      }

      &:has(.secondary) {
        &:hover .default {
          opacity: 0; /* On hover, hide the main image */
        }
        &:hover .secondary {
          opacity: 1; /* On hover, show the hover image */
        }
      }
    }
    .name-prices {
      padding: 5px 10px !important;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      gap: 10px;
      width: 100%;
    }
    .fill-heart,
    .heart {
      position: absolute;
      z-index: 999;
      bottom: 0;
      top: 315px;
      right: 5px;
      margin: 10px;
      font-size: 14px;
    }

    div {
      h3 {
        font-size: 10px;
        text-align: left;
        letter-spacing: 1.2px;
      }
      span {
        font-size: 12px;
        letter-spacing: 1.5px;
        padding-top: 3px;
      }
    }
    .prices {
      gap: 10px;

      span {
        font-size: 10px;
        letter-spacing: 1.5px;
      }
      .original-price {
        font-size: 10px;
      }
    }
  }
}
