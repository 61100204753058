.catalog-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-around;
  align-items: center;
  gap: 25px;

  .sidemenu-container {
    position: absolute;
    z-index: 999;
    top: 40vh;
    padding: 0;
    width: 100%;
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .catalog-list {
    // flex-direction: column;
    // align-items: center;
    // gap: 20px;
  }
  .sidemenu-container {
    display: none;
  }
}
//   .product-list-container {

//   }
//   .filters-container {
//   }
