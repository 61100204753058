.social-section-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;

  margin: 20px 0;
  padding: 10px 15px 10px 20px;

  .first-case {
    // width: 173px;
    width: 100%;
    height: 380px;
    background: #cececf;
    border-radius: 3px;
    position: relative;

    h2 {
      position: absolute;
      color: #000;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 4.5px;
      transform: rotate(-90deg);
      top: 45%;
      left: -25%;
    }
  }

  .posts-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;

    .post {
      background: #cececf;
      border-radius: 3px;
      height: 380px;
    }
  }

  img {
    border-radius: 3px;
    width: 100%;
    height: 380px;
    max-height: 380px;
    min-height: 380px;
    object-fit: cover;
  }

  //SWIPER
  .swiper {
    margin-right: unset;
  }
  .swiper-wrapper {
    justify-content: flex-end;
  }
  .swiper-slide {
    &:nth-child(1) {
      // width: 175px !important;
      width: 10% !important;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      width: 29% !important;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .social-section-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;

    margin: 10px 0;
    padding: 10px 15px;

    .first-case {
      // width: 170px;
      width: 100%;
      height: 50vh;
      position: relative;

      h2 {
        position: absolute;
        font-size: 25px;
        transform: rotate(-90deg);
        top: 45%;
        left: 0%;
      }
    }

    .posts-list {
      gap: 5px;

      .post {
        background: #cececf;
        border-radius: 3px;
        height: 50vh;
      }
    }

    img {
      height: 50vh;
      max-height: 50vh;
      min-height: 50vh;
    }

    .swiper-wrapper {
      justify-content: flex-start;
    }
    .swiper-slide {
      &:nth-child(1) {
        // width: 30%;
        width: 50% !important;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 50% !important;
      }
    }
  }
}
