.customer-adresses-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  margin: auto;

  .adresses-edit {
    display: flex;
    width: 750px;
    // width: 660px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    opacity: 0.9;
    background: #fbfbfb;
    border-radius: 3px;
    text-transform: uppercase;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

    h3 {
      color: #515151;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    h6 {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1.8px;
    }
    .profile-edit-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      width: 100%;

      .two-fields-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        width: 100%;

        div {
          width: 100%;
        }
      }

      .not-specified {
        color: #b8b8b8;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        width: 100%;
        border-radius: 3px;
        padding: 10px;
      }

      #defaultBilling,
      #defaultDelivery {
        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          gap: 8px;
          input {
            width: auto;
          }
        }

        input {
          width: auto;
        }
      }
    }
    input,
    span {
      color: #515151;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      width: 100%;
      padding: 2px 10px;

      &:disabled {
        outline: none;
        background: none;
        border: 0.75px solid transparent;
        border-radius: 3px;
        padding: 5px 7px;
      }

      &:focus {
        outline: none;
      }
    }

    button {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2.1px;
      outline: none;
      border: none;
      background: none;
      align-self: flex-end;
      cursor: pointer;
    }
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.9px;
      width: 100%;

      input {
        width: 100%;
        border: 0.75px solid #b8b8b8;
        border-radius: 3px;
        padding: 5px 7px;
      }
    }
    .adresses-edit-bottom {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      width: 100%;
      margin-top: 30px;

      button {
        svg {
          display: flex;
          width: 20px;
          height: 20px;
          scale: 1;
          transition: all 0.3s ease-in-out;

          &:hover {
            scale: 1.1;
          }
        }
      }
    }
    .setDefault {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-top: 25px;

      input {
        width: auto;
      }
    }
  }

  // PLACEHOLDER
  .no-addresses {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 100px auto 0 auto;

    text-transform: uppercase;
    text-align: center;
    h3 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 3px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #515151;
      opacity: 0.5;
    }
  }

  //LOADER
  .loader-container {
    height: 100%;
    width: 100%;
    min-height: 340px;

    span {
      width: auto;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .customer-adresses-content {
    flex-direction: column-reverse;
    gap: 30px;
    width: 100%;

    .fade-in-up {
      width: 100% !important;
    }

    .adresses-edit {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      align-items: center;
      gap: 10px;

      h3 {
        font-size: 16px;
        letter-spacing: 2px;
      }
      h6 {
        font-size: 12px;
        letter-spacing: 1.5px;
      }
      .profile-edit-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        gap: 15px;

        .two-fields-row {
          display: flex;
          flex-direction: column;
        }
        .not-specified {
          font-size: 12px;
          padding: 10px;
        }

        #defaultBilling,
        #defaultDelivery {
          label {
            gap: 15px;
          }
        }
      }
      input,
      span {
        font-size: 12px;
        letter-spacing: 1.5px;
      }

      button {
        font-size: 12px;
        letter-spacing: 2px;
        align-self: center;
      }
      label {
        font-size: 12px;
        letter-spacing: 1.8px;
      }
      .adresses-edit-bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        margin-top: 25px;
      }
    }

    // PLACEHOLDER
    .no-addresses {
      gap: 15px;
      margin: 70px auto 0 auto;

      h3 {
        font-size: 18px;
        letter-spacing: 1.8px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1.2px;
      }
    }

    //LOADER
    .loader-container {
      height: 100%;
      width: 100%;
      min-height: 70vh;
    }
  }
}
