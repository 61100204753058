.payment-success-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //   justify-content: space-between;
  gap: 50px;
  padding: 30px;
  height: 80vh;
  overflow: hidden;

  .sidemenu-container.hidden {
    opacity: 1 !important;
  }
  .payment-success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    // margin-left: -100px;
    height: 70vh;

    h6 {
      font-size: 30px;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      z-index: 99;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      white-space: pre-line;
      width: 100%;
      text-align: center;
    }
    .primary-button {
      width: 30%;
      margin: 20px auto;
    }
  }

  .payment-failed {
    height: 400px !important;
    margin-bottom: -50px;
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 20px 30px;
    overflow: hidden;
    height: 50vh;

    .sidemenu-container {
      display: none;
    }

    .payment-success-content {
      text-align: center;
      gap: 10px;
      //   height: auto;
      div {
        &:first-child:not(.payment-failed) {
          height: 100px !important;
        }
      }

      h6 {
        font-size: 22px;
        letter-spacing: 1.8px;
      }
      p {
        font-size: 12px;
        letter-spacing: 1.5px;
      }
      .primary-button {
        width: 60%;
        margin: 20px auto;
      }
    }
    .payment-failed {
      height: 200px !important;
    }
  }
}
