.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  height: 100%;
  width: 100%;
  span {
    width: auto;
  }
}
