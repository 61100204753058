/* FADE IN UP */
.fade-in-up {
  opacity: 0;
  /* transform: translateY(10px); */
  transform: translateY(20px);
  transition: opacity 1.5s, transform 2.5s ease;
  width: 100%;
}

@media screen and (max-width: 520px) {
  .fade-in-up {
    transform: none;
    opacity: 1;
  }
  .fade-in-up-active {
    transform: none;
  }
}

.fade-in-up-active {
  opacity: 1;
  transform: translateY(0);
}

/* MODAL */
.fade-in-up-modal {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.5s, transform 1.5s ease;
  width: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
}

@media screen and (max-width: 520px) {
  .fade-in-up-modal {
    transform: translateY(25px);
  }
}

.fade-in-up-active-modal {
  opacity: 1;
  transform: translateY(-50px);
}

@media screen and (max-width: 520px) {
  .fade-in-up-active-modal {
    transform: translateY(-25px);
  }
}

/* FADE IN RIGHT */
.fade-in-right {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 1.5s, transform 2.5s ease;
  width: 100%;
}
.fade-in-right-active {
  opacity: 1;
  transform: translateX(0);
}

@media screen and (max-width: 520px) {
  .fade-in-right {
    transform: translateX(0px);
  }
}

/* FADE IN LEFT */
.fade-in-left {
  opacity: 0;
  transform: translateX(-10%);
  transition: opacity 1.5s, transform 2.5s ease;
}

.fade-in-left.fade-in-left-active {
  opacity: 1;
  transform: translateX(0);
}

@media screen and (max-width: 520px) {
  .fade-in-left {
    transform: translateX(-50%);
  }
}
