.range-slider-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.slider {
  width: auto;
}
.range-slider {
  margin-top: 27px;
  appearance: none;
  height: 4px;
  border-radius: 500px;
  /* background: GREY; */
  /* background: linear-gradient(
    to right,
    #c6385b 0%,
    #f6e1e6 70%
  ); */
  background: linear-gradient(
    to right,
    #c6385b 0%,
    #c6385b 10%,
    #f6e1e6 30%,
    #f6e1e6 100%
  );

  outline: none;
  border: none;
  opacity: 0.7;
  transition: opacity 0.5s;

  padding: 0;
}

.range-slider::-webkit-slider-thumb {
  appearance: none;
  width: 24px;
  height: 24px;
  background: #da5b7a;
  border-radius: 50%;
  cursor: pointer;
}

.range-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background: #da5b7a;
  cursor: pointer;
}

.range-slider-indications {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 0;
  height: 24px;

  font-size: 12px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.25);
}

.selected-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 75px;
  height: 60px;

  border: 2px solid #f9ebef;
  border-radius: 8px;
}
.range-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
  height: 60px;
}

/* RESPONSIVE  */
@media screen and (max-width: 768px) {
  .range-slider-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .slider {
    width: 100%;
  }

  .selected-value {
    height: auto;
  }
}

.range-slider-input {
  display: none;
}
