.progress-bar-container {
  width: 555px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    list-style: none;

    li {
      // width: 100%;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #cfcfcf;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: #515151;
      }
    }
    .active {
      color: #515151;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .progress-bar-container {
    width: 100%;

    ul {
      padding: 0 20px;
      gap: 0;
      li {
        width: fit-content;
        font-size: 10px;
        letter-spacing: 1px;
        text-align: center;
      }
    }
  }
}
