.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  text-transform: uppercase;
  margin: 60px 0;

  .contact-us-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    h1 {
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 4.5px;
    }
    p {
      color: #676767;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      width: 650px;
    }
  }

  .contact-us-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 150px;
    padding: 0 120px;
    width: 100%;

    .contact-infos {
      display: flex;
      flex-direction: column;
      gap: 20px;
      min-width: 25%;

      div {
        display: flex;
        flex-direction: column;
        h6 {
          color: #515151;
          font-size: 20px;
          font-weight: 500;
          text-transform: uppercase;
          margin-bottom: 12px;
        }
        p {
          color: #515151;
          font-size: 15px;
          font-weight: 400;
          text-transform: uppercase;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .social-medias {
        span {
          color: rgba(0, 0, 0, 0.8);
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 3px;
        }
        a {
          display: flex;
          align-items: center;
          gap: 10px;
          color: rgba(0, 0, 0, 0.8);
          font-size: 15px;
          font-weight: 400;
          letter-spacing: 3px;
          text-decoration: none;
        }
      }
      img {
        margin-top: 15px;
        width: 330px;
        height: 300px;
        border-radius: 3px;
      }
    }
  }

  /////////FORM CONTACT//////////////////////////////
  .contact-form-container {
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    justify-content: center;
    align-self: flex-start;
    // width: 100%;
    gap: 20px;

    .contact-form {
      width: 550px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

      #form-contact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
        width: 100%;

        div {
          width: 100%;
          label {
            width: inherit;
            color: #515151;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
          input,
          textarea {
            width: 100%;
            text-transform: uppercase;
            border: none;
            border-bottom: 1px solid #b8b8b8;
            outline: none;
            padding: 10px 0;
            color: #515151;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 2px;
            &::placeholder {
              color: #cfcfcf;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: 2px;
              text-transform: uppercase;
            }
          }
        }
      }
    }

    .contact-form-footer {
      width: 100%;
      text-align: center;
      a {
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      .signup-error,
      .signup-success {
        display: block;
        color: red;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 30px;
      }
      .signup-success {
        color: green;
      }
    }
  }
}

//HEBREW
.he {
  .contact-us-container {
    direction: rtl;
  }
}

//RESPONSIVE
@media (max-width: 1200px) {
  .contact-us-container {
    gap: 30px;
    margin: 50px 20px;

    .contact-us-header {
      gap: 20px;
      h1 {
        font-size: 25px;
        font-weight: 400;
        letter-spacing: 4px;
      }
      p {
        font-size: 10px;
        letter-spacing: 1.5px;
        width: 80%;
      }
    }

    .contact-us-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 100px;
      padding: 50px 10px;
      width: 100%;

      .contact-infos {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 25%;

        div {
          display: flex;
          flex-direction: column;
          h6 {
            font-size: 16px;
            margin-bottom: 10px;
          }
          p {
            color: #515151;
            font-size: 12px;
            letter-spacing: 1.2px;
          }
        }
        .social-medias {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          column-gap: 20px;
          h6 {
            width: 100%;
          }
          span {
            font-size: 12px;
            letter-spacing: 2px;
            width: fit-content;
          }
          a {
            font-size: 12px;
            letter-spacing: 2px;
          }
        }
        img {
          margin-top: 15px;
          width: 100%;
          height: 300px;
        }
      }
    }

    /////////FORM CONTACT//////////////////////////////
    .contact-form-container {
      width: 100%;

      .contact-form {
        width: 100%;
        gap: 50px;

        #form-contact {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 25px;
          width: 100%;

          div {
            width: 100%;
            label {
              width: inherit;
              font-size: 12px;
              letter-spacing: 1.5px;
            }
            input,
            textarea {
              width: 100%;
              font-size: 12px;
              letter-spacing: 1.5px;
              &::placeholder {
                color: #cfcfcf;
                font-size: 12px;
                letter-spacing: 1.5px;
              }
            }
          }
        }
      }

      .contact-form-footer {
        a {
          font-size: 14px;
          letter-spacing: 1.5px;
        }
        .signup-error,
        .signup-success {
          display: block;
          font-size: 12px;
          letter-spacing: 1.5px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
