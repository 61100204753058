.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  background: #eeeeee;
  min-height: 100vh;
  padding: 0 0 50px 0;
  transition: all 0.5s ease;

  .inner-container {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;

    .left-checkout,
    .right-checkout {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
    }
    .right-checkout {
      flex: 2;
    }
    .left-checkout {
      flex: 3;
    }
  }

  h4,
  label {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    text-transform: uppercase;

    .three-fields-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      width: 100%;

      div {
        width: 100%;
      }
    }
  }
  label {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 7px;

    input,
    select,
    textarea {
      width: 100%;
      border-width: 0.1px 0.2px 1px 0.2px;
      border-color: #b8b8b8;
      border-top: #f5f5f5 solid 1px;
      border-bottom: #b8b8b8 solid 1px;
      border-right: #f5f5f5 solid 1px;
      border-left: #f5f5f5 solid 1px;
      border-style: solid;
      border-radius: 2px;

      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      color: #515151;

      padding: 5px;

      &:focus {
        outline: none;
      }
      &::placeholder {
        color: lightgrey;
        text-transform: uppercase;
      }
    }
    textarea {
      text-transform: none;
    }

    option {
      display: flex;
      justify-content: space-between;
    }
  }
  #deliveryInstruction {
    width: 100%;
  }
  .primary-button {
    text-transform: uppercase;
  }

  .error-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 7px;
    color: red;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    max-height: 0;

    &.show {
      opacity: 1;
      max-height: 100px;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .checkout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    width: 100%;
    min-height: 100vh;
    padding: 0 0 50px 0;
    overflow-x: hidden;

    .inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      gap: 20px;
      padding: 0 20px;

      .left-checkout,
      .right-checkout {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
      }
    }

    h4,
    label {
      font-size: 12px;
      letter-spacing: 1.2px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 25px;

      .three-fields-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        width: 100%;

        div {
          width: 100%;
        }
      }
    }
    label {
      font-size: 12px;
      letter-spacing: 1.5px;
      gap: 7px;

      input,
      select,
      textarea {
        font-size: 12px;
        letter-spacing: 1.5px;
        padding: 5px;
      }

      option {
        display: flex;
        justify-content: space-between;
      }
    }

    .error-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 7px;
      font-size: 12px;
      letter-spacing: 2px;
    }
  }
}
