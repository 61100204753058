#file-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  gap: 18px;

  /* width: 1067px; */
  height: 64px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
#file-input-container h6 {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.file-input-subtitle {
  font-weight: 300;
  color: #000000;
  padding-top: 12px;
}
#file-input-container label {
  align-self: self-end;
}
input[type="file"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 12px;
  gap: 10px;

  width: 115px;
  height: 54px;

  background: #f7f7f7;
  border-radius: 8px;
  display: none;
}
.file-input,
.choose-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 12px;
  gap: 10px;

  width: 115px;
  height: 54px;
  background: #f7f7f7;
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  color: #6f6f70 !important;
}
.file-input-indication {
  height: 48px;
  font-weight: 300;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  color: #6f6f70;
}
.file-input-indication .file-name {
  width: 100%;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
}
