.sidebar {
  position: fixed;
  top: 0;
  right: -700px; /* Initially hidden outside the viewport */
  width: 670px;
  height: 100vh;
  overflow-y: scroll;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: right 0.4s ease-in-out;

  display: flex;
  padding: 50px 20px 40px 20px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.sidebar.open {
  right: 0; /* Slide in from the right when open */
}

.overlay {
  filter: blur(5px);
  transition: all 0.4s ease-in;

  height: 100dvh;
  overflow-y: hidden;
  z-index: 0;
  display: block;
}

/* Show overlay when sidebar is open */
.sidebar.open + .overlay {
  display: block;
  transition: all 0.4s ease-in-out;
}
.app-container {
  filter: blur(0);
  transition: all 0.5s ease-in;
}
.loader-blur {
  height: 100dvh !important;
  position: relative;
  overflow: hidden;
  // background: #fff;
  filter: blur(10px);
  transition: all 0.5s ease-in;
}

/* SCROLLBAR  */
.sidebar::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-track {
  background-color: #000;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #fff;
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgb(255, 255, 255) 40%,
    transparent 100%,
    rgb(255, 255, 255) 60%,
    transparent
  );
  border-radius: 10px;
}

// RESPONSIVE
@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    right: -700px;
    // width: 0;
    // height: 0;
    overflow-y: scroll;
    // overflow-y: hidden;

    box-shadow: unset;
    transition: all 0.4s ease-in-out;

    display: flex;
    padding: 50px 20px 30px 20px;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
  }

  .sidebar.open {
    height: 100vh;
    width: 100%;
    right: 0;
    overflow-x: hidden;
  }

  // hide the overlay beside the sidebar when it's open
  .overlay {
    height: 100dvh;
    overflow: hidden;
  }

  .sidebar.open + .overlay {
    display: block;
    transition: all 0.4s ease-in-out;
  }

  .app-container {
    // commented out because avoid the sticky effects
    // overflow: hidden;
    filter: blur(0);
    transition: all 0.5s ease-in;
  }

  /* SCROLLBAR  */
  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .mobile-menu-open {
    height: 100dvh;

    .filters-container {
      display: none;
    }
  }
}
