.catalog-list-container {
  background: #fff;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 25px;

  h2 {
    color: #000;
    font-size: 35px;
    font-weight: 500;
    letter-spacing: 7px;
    align-self: center;
    text-transform: uppercase;
  }

  .catalog-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    // justify-content: space-evenly;
    row-gap: 25px;
    column-gap: 30px;
    width: 100%;
    min-height: 50vh;
    margin: auto;

    .empty-item {
      width: 330px;
      height: 0px;
      background: #f4f4f400;
      border-radius: 10px;
    }
  }

  .no-products {
    text-align: center;
    white-space: pre-line;
    position: absolute;
    top: 100px;
    left: -7vw;
    transform: translateX(7vw);

    width: 100vw;
    height: 20vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    span {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 70px;
      color: #000;
      background: #fff;
      padding: 20px;
      border-radius: 50%;
    }
    h3 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 3px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #515151;
      opacity: 0.5;
    }

    .primary-button {
      width: 30%;
    }
  }

  .search-result {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }

    span {
      color: #676767;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .subitem-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    &.he {
      flex-direction: row-reverse;
      justify-content: flex-end;

      span {
        flex-direction: row-reverse;
      }
    }

    span {
      border: solid 1px rgb(147, 147, 147);
      border-radius: 2px;
      text-transform: uppercase;
      font-size: 12px;
      padding: 8px 10px;
      font-weight: 400;
      color: #636262;
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 3px;

      svg {
        margin-top: 2px;
        font-size: 20px;
      }
    }
  }

  .loader {
    margin-top: 50px;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .catalog-list-container {
    padding: 10px 0;

    h2 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 5px;
    }

    .catalog-list {
      align-items: flex-start;
      justify-content: space-between;
      row-gap: 20px;
      column-gap: 0px;
      flex-wrap: wrap;
      width: 100%;
      min-height: 50vh;
      margin: auto;

      .single-item {
        width: 45vw;
        min-width: 45vw;
        max-width: 45vw;
        height: 370px;
        max-height: 370px;
        min-height: 370px;
        overflow: hidden;

        .fill-heart,
        .heart {
          // position: absolute;
          // z-index: 99999999;
          // bottom: 0;
          // top: 285px;
          // right: 5px;
          // margin: 10px;
          font-size: 14px;
        }

        .image-container {
          position: relative;
          // overflow: hidden;
          height: 270px;
          min-height: 270px;
          max-height: 270px;
          width: 100%;
          min-width: 100%;
          max-width: 100%;

          img {
            padding: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
      .name-prices {
        div {
          span {
            letter-spacing: 1.2px;
          }
        }
        .prices {
          gap: 10px;

          span {
            font-size: 10px;
            letter-spacing: 1.5px;
          }
          .original-price {
            font-size: 10px;
          }
        }
      }
    }

    .no-products {
      text-align: center;
      white-space: pre-line;
      transform: translateX(0);
      position: unset;

      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      gap: 20px;

      h3 {
        font-size: 18px;
        letter-spacing: 2.5px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1.2px;
      }

      .primary-button {
        width: 50%;
      }
    }

    .search-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      gap: 0px;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }

      span {
        font-size: 12px;
        letter-spacing: 1.2px;
      }
    }
    .subitem-category {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      span {
        font-size: 10px;
        padding: 8px 10px;
        font-weight: 400;
        width: fit-content;

        svg {
          margin-top: 2px;
          font-size: 20px;
        }
      }
    }

    .loader {
      margin-top: 50px;
    }
  }
}
