.purchases-modal {
  position: fixed;
  top: 55% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 30px 45px;
  width: 950px;
  // width: 920px;
  height: fit-content;

  background: #ffffff;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  backdrop-filter: blur(8px);
  text-transform: uppercase;

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #b8b8b8;

    h3 {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 3.3px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 2.2px;
      cursor: pointer;
    }
  }

  .order-infos {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 2.5px;

    .status {
      color: green;
    }
  }

  .order-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .order-adresses {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .date {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 2.5px;
      }
    }

    .billing-adress,
    .shipping-adress {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 380px;
      padding: 12px;
      border: 0.5px solid #cfcfcf;
      border-radius: 3px;

      h6 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 2.5px;
        margin-bottom: 10px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.9px;
      }
    }
    .order-articles {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      h6 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 3.3px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .article {
        display: flex;
        gap: 20px;
      }
      img {
        width: 80px;
        object-fit: cover;
        margin-right: 10px;
      }
      .article-infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 70px;

        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.7px;
      }
      .name {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.9px;
        margin-bottom: 10px;
      }
      .price {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.9px;
        margin-left: auto;
      }
      .purchases-total {
        font-weight: 800;
        padding: 0 10px;
        text-align: center;
      }
    }
  }

  .order-assistance {
    display: flex;
    gap: 10px;
    align-self: center;
    margin-top: 20px;

    font-size: 11px;
    font-weight: 400;
    letter-spacing: 2.2px;

    a {
      color: #b8b8b8;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: #000;
      }
    }
  }
}

//MODAL OPEN

.blur {
  margin: 0;
  height: 100vh !important;
}

//RESPONSIVE
@media (max-width: 768px) {
  .purchases-modal {
    position: fixed;
    transform: translate(-50%, 20%);

    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 30px;
    width: 90%;
    height: 80vh;
    overflow-y: scroll;

    .modal-header {
      h3 {
        font-size: 16px;
        letter-spacing: 2px;
      }
      span {
        font-size: 10px;
        letter-spacing: 1.8px;
      }
    }

    .order-infos {
      font-size: 12px;
      letter-spacing: 1.8px;
    }

    .order-details {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 40px;

      .order-adresses {
        gap: 10px;
        .date {
          font-size: 10px;
          letter-spacing: 1.8px;
        }
      }

      .billing-adress,
      .shipping-adress {
        padding: 10px;
        width: 100%;

        h6 {
          font-size: 12px;
          letter-spacing: 1.8px;
          margin-bottom: 5px;
        }
        span {
          font-size: 8px;
          letter-spacing: 1px;
        }
      }
      .order-articles {
        display: flex;
        flex-direction: column;
        gap: 50px;

        width: 100%;

        h6 {
          font-size: 16px;
          letter-spacing: 3px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .article {
          display: flex;
          gap: 20px;
        }
        img {
          width: 80px;
          height: 120px;
          margin-right: 0px;
        }
        .article-infos {
          margin-right: 0px;
          font-size: 10px;
          letter-spacing: 1.2px;
        }
        .name {
          font-size: 12px;
          letter-spacing: 1.2px;
          margin-bottom: 7px;
        }
        .price {
          font-size: 14px;
          letter-spacing: 1.5px;
          margin-left: 10px;
        }
        .purchases-total {
          padding: 0 10px;
          text-align: center;
        }
      }
    }

    .order-assistance {
      margin-top: 10px;
      font-size: 10px;
      letter-spacing: 1.5px;
    }
  }
}
