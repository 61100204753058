.purchases-container {
  transition: all 1s ease-out;

  .customer-purchases-content {
    min-width: 750px;
    margin: auto;
  }

  .no-purchases {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 100px auto 0 auto;

    text-transform: uppercase;
    text-align: center;
    h3 {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 3px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 2px;
      color: #515151;
      opacity: 0.5;
    }
  }
  a {
    opacity: 1;
    &:hover {
      font-weight: 400;
    }
  }
  .primary-button {
    width: 270px;
    margin: inherit;

    a {
      color: #fff !important;
      opacity: 1;
      text-transform: uppercase;
    }
  }
  .btn-42:hover {
    a {
      color: #000 !important;
    }
  }
}
.blur {
  filter: blur(10px);
  background-color: #fff;
  pointer-events: none;
  height: auto;
  overflow: hidden;
  transition: all 1.2s ease-in;
}

//RESPONSIVE
@media (max-width: 768px) {
  .purchases-container {
    .customer-purchases-content {
      min-width: 0;
      width: 100%;
    }

    .no-purchases {
      gap: 20px;
      margin: 100px auto 0 auto;

      h3 {
        font-size: 18px;
        letter-spacing: 1.8px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1.2px;
      }
    }

    .primary-button {
      width: 100%;
    }
  }
}
