.suggestions-container {
  display: flex;
  flex-direction: column;
  margin: 60px 0 50px 0;
  padding: 10px 15px 10px 20px;
  width: 100%;

  h2 {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 8px;
    text-transform: uppercase;
    text-align: center;
    align-self: center;
    margin-bottom: 50px;
  }

  .suggestions-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 40px;
    width: 100%;

    .single-item {
      height: fit-content;
    }

    .no-products {
      color: #000;
      font-size: 24px;
      font-weight: 100;
      letter-spacing: 0.3em;
      margin: auto;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .suggestions-container {
    // flex-direction: row;
    margin: 20px 0 20px 0;
    padding: 10px 15px;

    h2 {
      font-size: 22px;
      letter-spacing: 6px;
      margin-bottom: 20px;
    }

    .single-item {
      .name-prices {
        width: 210px;
      }

      .fill-heart,
      .heart {
        right: 20%;
        font-size: 14px;
      }
    }

    .suggestions-list {
      column-gap: 30px;
      justify-content: center;
      .no-products {
        font-size: 20px;
        letter-spacing: 0.2em;
        margin: auto;
      }
    }
  }
}
