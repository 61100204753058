.customer-private-club-content {
  width: 850px;
  min-width: 850px;
  max-width: 850px;
  margin: auto;

  display: flex;
  flex-direction: row;
  gap: 40px;
  flex-wrap: wrap;
}
.private-club-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .private-club-image {
    width: 700px;
    height: 250px;
    background: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/7986db36-cc57-4730-27d0-37b748a8b500/public");
    opacity: 0.8;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .primary-square {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background: #fff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 12px 0;
      color: #515151;

      border: 15px solid rgba(182, 179, 179, 0.073);
      border-radius: 3px;
    }

    h3 {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 3px;
      span {
        font-weight: 700;
      }
    }
    p {
      padding: 5px 20px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.8px;
      text-transform: uppercase;
    }
  }
  .secondary-squares {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    gap: 24px;
    margin-top: 24px;

    div:nth-child(1),
    div:nth-child(3) {
      width: 230px;
    }
  }
  .secondary-square {
    display: flex;
    width: 290px;
    height: auto;

    padding: 25px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    background: #fff;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.05);
    border-radius: 3px;

    text-align: center;
    color: #515151;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1.8px;
    text-transform: uppercase;

    p {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.5px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-top: 24px;
    width: 80%;

    .primary-button,
    .secondary-button {
      width: 100%;
      padding: 20px 0;
      text-transform: uppercase;
    }
  }
}

//RESPONSIVE
@media (max-width: 900px) {
  .customer-private-club-content {
    width: 100%;
    min-width: 0;
    max-width: 100%;
    margin: auto;

    gap: 40px;
    flex-wrap: wrap;
  }
  .private-club-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .primary-square {
      display: flex;
      padding: 20px;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      div {
        gap: 10px;
        text-align: center;
      }

      h3 {
        font-size: 20px;
        letter-spacing: 2px;
      }
      p {
        padding: 5px 20px;
        font-size: 12px;
        letter-spacing: 1.5px;
      }
    }
    .secondary-squares {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 20px auto;
      gap: 20px;

      div:nth-child(1),
      div:nth-child(3) {
        width: 100%;
      }
    }
    .secondary-square {
      width: 100%;
      gap: 10px;
      font-size: 14px;
      letter-spacing: 1.8px;

      p {
        font-size: 12px;
        letter-spacing: 1.5px;
      }
    }

    .buttons {
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 15px;
      width: 100%;

      .primary-button,
      .secondary-button {
        padding: 20px 0px;
      }
    }
  }
}
