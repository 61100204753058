.catalog-cards-container {
  display: flex;
  //   flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px 15px;
  gap: 15px;
  width: 100%;

  .cards-top-line {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end !important;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content !important;
    gap: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card {
      width: 32%;
      height: 380px;

      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        width: 36%;
      }
      &:nth-child(3) {
        width: 36%;
      }
      &:nth-child(4) {
        width: 36%;
      }
      &:nth-child(5) {
        width: 36%;
      }
      &:nth-child(6) {
        width: 25%;
      }
    }
  }

  //   .cards-top-line,
  //   .cards-bottom-line {
  //     display: flex;
  //     flex-direction: row;
  //     justify-content: space-between;
  //     align-items: flex-start;
  //     gap: 15px;
  //     width: 1200px;
  //     height: 380px;

  //     img {
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //     }
  //   }

  .card {
    // width: 100%;
    height: 100%;
    position: relative;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;

      font-size: 36px;
      font-weight: 400;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-align: center;

      cursor: pointer;
      opacity: 0;
      transition: all 0.3s ease-out;

      &.overlayed {
        opacity: 1;
        color: #fff;
        background: linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.5)
        );
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.9s ease;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .catalog-cards-container {
    padding: 10px 0px;
    gap: 10px;
    width: 100%;

    .cards-top-line {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between !important;
      gap: 10px;

      img {
        width: 100%;
        height: 100%;
        object-position: top;
        object-fit: cover;
      }

      .card {
        // width: 32%;
        width: 100%;
        // height: 250px;
        height: 49vh;

        &:nth-child(1) {
          width: 100%;
        }
        &:nth-child(2) {
          width: 100%;
        }
        &:nth-child(3) {
          width: 100%;
        }
        &:nth-child(4) {
          width: 100%;
        }
        &:nth-child(5) {
          width: 100%;
        }
        &:nth-child(6) {
          width: 100%;
        }
      }
    }

    .card {
      height: 100%;
      position: relative;

      h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        letter-spacing: 1px;
        font-size: 24px;

        //overlayed
        opacity: 1;
        background: linear-gradient(
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.5)
        );
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.overlayed {
          opacity: 1;
          transition: all 0.9s ease;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
