.primary-button {
  background: #000;
  display: flex;
  height: 50px;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;

  outline: none !important;
  position: relative;

  a,
  div,
  span {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    padding: 0px 20px;
  }

  &.disabled {
    background: #d4d4d4;
    border: 2px solid #d4d4d4;
    cursor: not-allowed;

    &:hover {
      background: #d4d4d4;
      border: 2px solid #d4d4d4;
    }
    &:after {
      background: #d4d4d4;
    }

    a,
    div {
      color: #ababab !important;
      pointer-events: none;
    }
  }
}

// HOVER EFFECTS
.btn-42 {
  border: 2px solid #000;
  color: #000 !important;
  z-index: 1;
}

.btn-42:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  background: #fff;
  transition: all 0.3s ease;
}

.btn-42:hover {
  a,
  div {
    color: #000;
  }
}

.btn-42:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

// RESPONSIVE
@media (max-width: 768px) {
  .primary-button {
    height: 40px;
    gap: 10px;
    transition: all 0.2s ease;
    outline: none !important;
    a,
    div,
    span {
      font-size: 14px;
      transition: all 0.2s ease;
      padding: 0px;
    }
  }
  // HOVER EFFECTS
  .btn-42:after {
    transition: all 0s ease;
  }
}
