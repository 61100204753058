.toggle-switch-container .check-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  font-weight: 400;
  font-size: 16px;
}
.toggle-switch-container input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 50px !important;
  height: 25px;
  background: #ececec;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.toggle-switch-container input:checked[type="checkbox"] {
  background: #7da6ff;
}

.toggle-switch-container input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}

.toggle-switch-container
  input:checked[type="checkbox"]::after {
  left: 50%;
}
