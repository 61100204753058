.cookies {
  transition: all 0.5s ease-in;
  background: rgba(20, 20, 20, 0.5);
  // border: 1px solid #000;
  border-radius: 5px 5px 0 0;
  max-height: 30vh;
  // height: 25vh;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -25vh auto;
  // transform: translate(95%, 0);
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 99999;
  white-space: pre-line;

  .cookies-container {
    background: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 15px 10px;
    border-radius: 5px 5px 0 0;
    // border: 2px solid #000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;

    img {
      width: 50px;
    }

    h6 {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      color: #555;
      text-align: center;
      a {
        color: #000;
        text-decoration: underline;
      }
    }

    .buttons {
      display: flex;
      margin-top: 10px;
      gap: 10px;

      button {
        text-transform: uppercase;
        padding: 5px 20px;
        border: 1px solid #000;
        cursor: pointer;
        transition: all 0.3s ease-in;

        font-size: 14px;
        font-weight: 300;
        letter-spacing: 1.5px;
      }
      :first-child {
        background: #000;
        color: #fff;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
      :last-child {
        background: #fff;
        color: #000;
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }

  &.close {
    // display: none;
    height: 0vh;
    max-height: 0vh;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease;

    .cookies-container {
      transition: all 0.3s ease;
      height: 0vh;
      display: none;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .cookies {
    max-height: 30vh;
    width: 100%;

    .cookies-container {
      padding: 15px 10px;

      h6 {
        font-size: 12px;
      }
      p {
        font-size: 10px;
        letter-spacing: 1px;
      }

      .buttons {
        display: flex;
        margin-top: 5px;
        gap: 10px;

        button {
          padding: 5px 20px;
          font-size: 10px;
          letter-spacing: 1.2px;
        }
      }
    }

    &.close {
      height: 0;
      width: 0;
      margin: 0;
    }
  }
}
