.legal-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 70px;
  margin: 60px 150px;
  text-transform: uppercase;
  white-space: pre-line;

  h1 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
  }
  h4 {
    direction: rtl;
  }
  p {
    direction: rtl;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    max-width: 1070px;
    text-align: justify;
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .legal-page-container {
    margin: 50px 20px;
    gap: 40px;
    h1 {
      font-size: 20px;
      letter-spacing: 2px;
    }
    h4 {
      font-size: 14px;
      letter-spacing: 1.5px;
    }
    p {
      font-size: 10px;
    }
  }
}
