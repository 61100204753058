.week-planning {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  height: 45px;

  &.extanded {
    height: 340px;
  }

  .week-day {
    display: flex;
    width: 120px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 0 5px 15px 5px;
    border-radius: 8px;
    color: #fff;
    height: 45px;

    transition: all 0.3s ease-in-out;

    &.selected {
      background: #da5b7a;
      height: auto;
      transition: all 0.3s ease-in-out !important;

      .button-bar-button {
        color: #fff;
      }
      div {
        background: #da5b7a;
        opacity: 1;
        transition: all 0.4s ease-in-out !important;
        z-index: 900;
      }

      input[type="time"] {
        // margin-right: 20px;
        width: 100% !important;
        text-align: center;
      }

      input[type="time"]::-webkit-calendar-picker-indicator {
        opacity: 0;
        z-index: 999;
        position: absolute;
        width: 15%;
        left: 35%;
      }
    }

    &.not-selected {
      transition: all 0.4s ease-in-out !important;
      background: #fff;

      .button-bar-button {
        color: #000;
      }
      div {
        opacity: 0;
        transition: all 0.4s ease-in-out !important;
        z-index: 0;
        display: none;
      }
    }

    label {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }

    .button-bar-button {
      font-weight: 500 !important;
      font-size: 14px;
      padding: 10px 0px;
      width: 120px;
      height: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      border-radius: 8px;
      cursor: pointer;
      white-space: nowrap;
      background-color: transparent;
      color: #fff;
      transition: all 0.3s ease-in-out;
      z-index: 900;

      &:hover {
        background-color: #da5b7a;
        color: #fff;
      }
      &.selected {
        background-color: #da5b7a;
        color: #fff;
      }
    }
    .time-duration,
    .arrived-time {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 5px;
      width: 100%;

      h6 {
        font-size: 13px;
        font-weight: 400;
        line-height: 1em;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
        width: 100%;
        justify-content: space-between;
        svg {
          width: 24px;
          height: 24px;
        }
        input {
          width: 100%;
          height: 35px;
        }
      }
    }
    .timeslots {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      gap: 5px;
      width: 100%;
      font-size: 13px;
      font-weight: 400;

      input[type="checkbox"] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;

        background-color: #fff;
        border-radius: 500px;
        width: 10px !important;
        height: 10px !important;
        padding: 5px;
        border: 1px solid #000;
        cursor: pointer;
      }
      input[type="checkbox"]:checked {
        background-color: #000;
        padding: 5px;
        border: 1px solid #fff;
      }
    }
    .arrived-time {
      margin-top: 10px;

      div {
        display: flex;
        flex-direction: row-reverse;
        position: relative;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.planning-label {
  margin-bottom: -30px;
}
