.size_guide-container {
  display: flex;
  flex-direction: column;
  gap: 50px;

  //   height: 100vh;
  width: 570px;
  text-transform: uppercase;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;

    span {
      &:nth-child(2) {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .size-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    h3 {
      color: #000000cc;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 4px;
    }

    table {
      //   width: 100%;
      //   border-collapse: collapse;
      border: 1px solid #00000033;
      border-radius: 5px;
      border-spacing: 0;
      text-align: left;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: 2px;

      tr {
        &:nth-child(1) {
          background: #00000011;
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }

      th,
      td {
        width: 100px;
        width: 25%;
        text-align: center;
        padding: 20px;
        border-bottom: 1px solid #00000033;
        border-right: 1px solid #00000033;

        &:last-child {
          border-right: none;
        }
      }

      th {
        font-weight: 600;
      }
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .size_guide-container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .header {
      font-size: 14px;
      letter-spacing: 1.5px;
    }

    .size-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      h3 {
        font-size: 16px;
        letter-spacing: 3px;
      }

      table {
        font-size: 10px;
        letter-spacing: 1.5px;

        th,
        td {
          width: 25%;
          padding: 15px;
        }
      }
    }
  }
}
