.dropdown-container {
  display: flex;
  flex-direction: column;
  gap: 7px !important;
  width: 100%;

  h4 {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
}
.dropdown {
  display: flex;
  position: relative;
  cursor: pointer;
  gap: 10px !important;
  width: 100%;
  height: fit-content;
  border-width: 0.1px 0.2px 1px 0.2px;
  border-color: #b8b8b8;
  border-top: #f5f5f5 solid 1px;
  border-bottom: #b8b8b8 solid 1px;
  border-right: #f5f5f5 solid 1px;
  border-left: #f5f5f5 solid 1px;
  border-style: solid;
  border-radius: 2px;

  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  color: #515151;
  text-align: left !important;

  &:focus {
    outline: none;
  }

  .header {
    width: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 15px;
    color: #515151;

    &.error {
      background-color: #ff0000 !important;
    }

    .icon {
      font-size: 13px;
      color: #515151;
      transform: rotate(0deg);
      transition: all 0.2s ease-in-out;
      // padding: 0 30px;
    }

    .icon.open {
      transform: rotate(90deg);
    }

    input {
      pointer-events: none;
      // background: #fff;
      cursor: pointer;
      width: 100%;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1.7px;

      &:focus {
        outline: none;
        background: #fff;
      }
      &:active {
        outline: none;
        background: #fff;
      }
      &:-webkit-autofill {
        -webkit-background-color: yellow !important;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #23232329;
    }

    div {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 10px;
    }
  }
  .default-value {
    color: #515151;
    border: none !important;
  }
  .selected {
    color: #515151;
  }
}

.dropdown-body {
  // display: none;
  position: absolute;
  top: 70px;
  opacity: 0;
  padding: 5px;
  z-index: -1;
  transition: all 0.2s ease-in-out;
}
.dropdown-body.open {
  display: block;
  z-index: 9999;
  opacity: 1;
  position: absolute;
  top: 45px;
  background-color: #fff;
  width: inherit;
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

.dropdown-item {
  padding: 10px;
  text-align: left;
  color: #454545;
  transition: all 0.3s ease;
  border-radius: 2px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: #b8b8b8;
    color: #fff;
  }
}
