.fidelity-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 70px 120px 70px;
  .fidelity-hero {
    display: flex;
    width: 90vw;
    height: 350px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: 
      //   url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/7986db36-cc57-4730-27d0-37b748a8b500/public"),
      linear-gradient(
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.5)
    );
    border-radius: 3px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .fidelity-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fbfbfb;
    width: 42%;
    margin-top: -100px;
    gap: 24px;
    padding: 60px;
    border-radius: 3px;

    h3 {
      color: #515151;
      text-align: center;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 3.3px;
    }

    p {
      color: #515151;
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      white-space: pre-line;
    }
  }

  .fidelity-privileges {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 60px;
    width: 90vw;

    .privilege {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      height: 170px;
      padding: 20px 30px;
      background: #fbfbfb;
      border-radius: 3px;

      img {
        width: 35px;
        height: 35px;
      }

      p {
        color: #515151;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  .btn-42 {
    color: #fff !important;
    border: 2px solid #000;
  }
  .primary-button {
    width: 100%;
    color: #fff;
  }
  .primary-button {
    margin-top: 0;
    width: 100%;
  }
  .btn-42:hover {
    a,
    div,
    span {
      color: #000 !important;
      opacity: 1;
      transition: all 0.3s ease;
      font-weight: 400;
    }
  }

  &.he {
    text-align: right;
    .privilege {
      justify-content: flex-start;
      height: 150px;
    }
  }
}
.he .fidelity-container {
  direction: rtl;
}

//RESPONSIVE
@media (max-width: 768px) {
  .fidelity-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px 30px 20px;
    .fidelity-hero {
      display: flex;
      width: 100%;
      height: 350px;
      // flex-direction: column;
      // align-items: center;
      // overflow: hidden;
      // background:
      //   //   url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/7986db36-cc57-4730-27d0-37b748a8b500/public"),
      //   linear-gradient(
      //   rgba(0, 0, 0, 0.2),
      //   rgba(0, 0, 0, 0.5)
      // );
    }

    .fidelity-description {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      width: 90%;
      margin-top: -50px;
      gap: 20px;
      padding: 30px;

      h3 {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 2px;
      }

      p {
        font-size: 12px;
        letter-spacing: 1.5px;
        text-align: center;
      }
    }

    .fidelity-privileges {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 30px;
      width: 100%;

      .privilege {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        width: 48%;
        height: 150px;
        padding: 20px 10px;

        img {
          width: 30px;
          height: 30px;
        }

        p {
          font-size: 10px;
          letter-spacing: 1.5px;
        }
      }
    }

    &.he {
      text-align: right;

      .privilege {
        justify-content: flex-start;
        height: 150px;
      }
    }
  }
}
