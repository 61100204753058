.ads-banner-container {
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
  padding: 10px 15px 10px 20px;

  .ads-banner {
    background: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/abc6960d-a63f-4732-6b03-6a74619e7300/public");
    background-repeat: no-repeat;
    background-color: #050607;
    background-size: 100%;
    background-position-x: 25vw;
    background-position-y: center;
    // background-position-y: -60vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 350px;
    padding: 20px 25px;
    align-items: flex-start;

    border-radius: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  }

  h3 {
    color: #fff;
    font-size: 64px;
    font-weight: 400;
    line-height: 1.2em;
    text-transform: uppercase;
  }
  h6 {
    color: #fff;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
  }

  span {
    color: #000;
    padding: 0;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    div {
      color: #000;
      font-size: 18px;
      // font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      padding: 0;
    }
  }

  // span {
  //   padding: 0;
  //   border-radius: 10px;
  //   transition: all 0.3s ease-in-out;
  //   position: relative;
  //   cursor: pointer;
  //   div {
  //     color: #fff;
  //     font-size: 20px;
  //     font-weight: 700;
  //     letter-spacing: 2px;
  //     text-transform: uppercase;
  //   }

  //   &:hover {
  //     div {
  //       color: #fff;
  //     }

  //     &::after {
  //       background-color: #fff;
  //       bottom: 0;
  //       transition: all 0.3s ease-in-out;
  //       border-radius: 10px;
  //     }
  //   }

  //   &::after {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     bottom: -2px;
  //     width: 100%;
  //     height: 1px;
  //     background-color: transparent;
  //     transition: all 0.3s ease-in-out;
  //   }
  // }
  // .login-button {
  //   &:hover {
  //     &::after {
  //       background-color: #ffffff00;
  //       bottom: 0;
  //       transition: all 0.3s ease-in-out;
  //       border-radius: 10px;
  //     }
  //   }
  // }

  .primary-button {
    background-color: #fff;
    padding: 5px 0px;
    border-radius: 10px;
    height: fit-content;
    min-width: 100px;
    margin: 10px 0 0;
    position: unset;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 30px rgba(255, 255, 255, 0.7);
    }
  }
  .btn-42 {
    border: none;
  }
}

.he .ads-banner-container {
  direction: rtl;

  .ads-banner {
    background-position-x: -25vw;
    background-position-y: center;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .ads-banner-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 15px;

    .ads-banner {
      background: url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/abc6960d-a63f-4732-6b03-6a74619e7300/public");
      background-repeat: no-repeat;
      background-color: #050607;
      background-size: 170%;
      background-position-x: 15vw;
      background-position-y: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      height: fit-content;
      padding: 30px 20px;
      // align-items: flex-start;

      border-radius: 15px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);

      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }

    h3 {
      font-size: 27px;
      font-weight: 400;
      line-height: 1.2em;
    }
    h6 {
      font-size: 16px;
      font-weight: 300;
    }

    .primary-button {
      font-size: 16px;
      letter-spacing: 1.5px;

      &:hover {
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.7);
      }
    }

    span {
      div {
        font-size: 14px;
        letter-spacing: 1.5px;
      }
    }

    //   &:hover {
    //     div {
    //       color: #fff;
    //     }

    //     &::after {
    //       background-color: #fff;
    //       bottom: 0;
    //       transition: all 0.3s ease-in-out;
    //       border-radius: 10px;
    //     }
    //   }

    //   &::after {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     bottom: -2px;
    //     width: 100%;
    //     height: 1px;
    //     background-color: transparent;
    //     transition: all 0.3s ease-in-out;
    //   }
    // }
    // .login-button {
    //   &:hover {
    //     &::after {
    //       background-color: #ffffff00;
    //       bottom: 0;
    //       transition: all 0.3s ease-in-out;
    //       border-radius: 10px;
    //     }
    //   }
    // }

    // .primary-button {
    //   background-color: #ffffff00;
    //   color: #000;
    //   padding: 5px 0px;
    //   border-radius: 10px;
    //   height: fit-content;
    //   width: fit-content;
    //   margin: 0;
    //   position: unset;
    //   font-size: 16px;
    //   font-weight: 700;
    //   text-transform: uppercase;
    //   letter-spacing: 1.5px;
    //   transition: all 0.3s ease-in-out;
    //   cursor: pointer;
    // }
    // .btn-42 {
    //   border: none;
    //   color: #fff !important;
    // }
    // div {
    //   width: fit-content;
    //   padding: 0;
    // }
  }
}
