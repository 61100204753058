.product-page-container {
  padding: 30px 35px 35px 60px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;

  position: relative;
  transition: all 0.4s ease-in-out;

  .product-infos {
    display: flex;
    column-gap: 5vw;
    justify-content: end;
  }
  .sidemenu-container {
    position: fixed;
  }
}

// RESPONSIVE
@media (max-width: 768px) {
  .product-page-container {
    padding: 30px 20px 35px 20px;
    row-gap: 30px;
  }
  .product-infos {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .sidemenu-container {
    display: none;
  }
}
