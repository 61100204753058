.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 92vh;
  gap: 20px;
  background: linear-gradient(
      180deg,
      rgba(211, 211, 211, 0.9) 10%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    url("https://imagedelivery.net/KxaxjiZHclDBBV_yzb3fDg/e67923d3-60bd-44e1-ff64-86eb3fef8f00/public");

  background-size: 100%;
  background-repeat: repeat-y;
  transition: all 0.4s ease-in;

  h1 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    background: #000;
    // background: #000000;

    background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 1;
  }
  p {
    font-size: 45px;
    font-weight: 700;
    letter-spacing: 1.2px;
    line-height: 1.3em;
    text-transform: uppercase;
    text-align: center;
    color: #000;
    width: 50%;
  }
  span {
    font-size: 20px !important;
    font-weight: 300;
  }
  .primary-button {
    margin: 0;
    width: fit-content;
  }
}
//RESPONSIVE
@media (max-width: 768px) {
  .not-found-container {
    h1 {
      font-size: 18px;
      letter-spacing: 1.8px;
    }
    p {
      font-size: 30px;
      letter-spacing: 1px;
      // line-height: 1.3em;
      width: 90%;
    }
    span {
      font-size: 18px !important;
      line-height: 0;
    }
    .primary-button {
      padding: 0 10px;
    }
  }
}
