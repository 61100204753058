.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 70px;
  text-transform: uppercase;
  margin: 100px 0;

  .aboutus-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    text-transform: uppercase;
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 4.5px;
    text-align: center;
  }

  img {
    width: 560px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 2px;
    text-align: center;
    width: 880px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    span {
      font-weight: 700;
    }
  }

  .brands-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 80px;
    width: 1270px;
    margin: 20px 0 130px 0;
    img {
      width: 200px;
      box-shadow: none;
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .about-us-container {
    gap: 70px;
    margin: 50px 10px;
    direction: rtl;

    h1 {
      font-size: 25px;
      letter-spacing: 4px;
    }

    img {
      width: 100%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    }
    p {
      font-size: 12px;
      letter-spacing: 1.5px;
      width: 100%;
      direction: rtl;
    }

    .brands-container {
      row-gap: 10px;
      column-gap: 20px;
      width: 100%;
      margin-bottom: 0;
      img {
        width: 70px;
      }
    }
  }
}
