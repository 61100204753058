.checkout-adress-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;

  .primary-button {
    width: 30%;
    margin: 0;
    div {
      font-size: 14px;
      padding: 5px 0;
    }
  }

  .checkout-adress-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    h2 {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    button {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border-radius: 5px;

      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        background: #f5f5f5;
      }
    }
  }

  .default-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    h6 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 2px;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1.2px;
      color: #515151;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .checkbox-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    label {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      gap: 10px;
    }
    .list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    input {
      width: auto;
    }
  }

  input[type="checkbox"] {
    width: auto;
    filter: sepia(0%) brightness(100%) hue-rotate(300deg)
      saturate(99%) contrast(300%);
  }
  input[type="checkbox"]:checked {
    filter: sepia(80%) brightness(100%) hue-rotate(300deg)
      saturate(99%) contrast(300%);
    accent-color: #0080ff;
  }

  .no-addresses {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    h3 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 2px;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.2px;
      color: #515151;
      opacity: 0.5;
    }

    .primary-button {
      width: 30%;
      margin: 0;
    }
  }
}

//HEBREW
.he .checkout-adress-container {
  direction: rtl;
}

//ADDRESS-MODAL
.address-modal-container {
  position: fixed;
  // top: 20% !important;
  left: 50% !important;
  transform: translate(-50%, 30%);
  transition: all 0.5s ease;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 30px 45px;
  max-height: 70vh;
  background: #fbfbfb;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  backdrop-filter: blur(8px);
  text-transform: uppercase;
  overflow-y: scroll;

  /* SCROLLBAR  */
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 40%,
      transparent 100%,
      rgba(0, 0, 0, 1) 60%,
      transparent
    );
    border-radius: 10px;
  }
  /* /////////////////////////// */

  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #b8b8b8;

    h3 {
      font-size: 22px;
      font-weight: 400;
      letter-spacing: 3.3px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 2.2px;
      cursor: pointer;
    }
  }
  #form-checkout-address {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .adresses-edit {
    display: flex;
    width: 670px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    opacity: 0.9;
    border-radius: 3px;
    text-transform: uppercase;

    h3 {
      color: #515151;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.5px;
    }
    h6 {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1.8px;
    }
    .profile-edit-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      width: 100%;

      .two-fields-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
        width: 100%;

        div {
          width: 100%;
        }
      }

      .not-specified {
        color: #b8b8b8;
        font-size: 13px;
        font-weight: 400;
        font-style: italic;
        width: 100%;
        border-radius: 3px;
        padding: 10px;
      }

      #defaultBilling,
      #defaultDelivery {
        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          gap: 8px;
          input {
            width: auto;
          }
        }

        input {
          width: auto;
        }
      }
    }
    input,
    span {
      color: #515151;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.8px;
      width: 100%;
      padding: 2px 10px;

      &:disabled {
        outline: none;
        background: none;
        border: 0.75px solid transparent;
        border-radius: 3px;
        padding: 5px 7px;
      }

      &:focus {
        outline: none;
      }
    }

    button {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2.1px;
      outline: none;
      border: none;
      background: none;
      align-self: flex-end;
      cursor: pointer;
    }
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.9px;
      width: 100%;

      input {
        width: 100%;
        border: 0.75px solid #b8b8b8;
        border-radius: 3px;
        padding: 5px 7px;
      }
    }
    .adresses-edit-bottom {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      width: 100%;
      margin-top: 30px;

      button {
        svg {
          display: flex;
          width: 20px;
          height: 20px;
          scale: 1;
          transition: all 0.3s ease-in-out;

          &:hover {
            scale: 1.1;
          }
        }
      }
    }
  }

  .loader-container {
    width: 100%;
    span {
      width: auto;
    }
  }
}

// ADDRESSES-MODAL
.adresses-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  .profile-select-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
  }
  .loader-container {
    width: 100%;
    span {
      width: auto;
    }
  }
}
.address-card {
  display: flex;
  width: 660px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  opacity: 0.9;
  background: #fbfbfb;
  border-radius: 3px;
  text-transform: uppercase;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);

  h6 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.9px;
    width: 100%;
  }
  p {
    color: #515151;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    span {
      padding-right: 4px;
    }
  }

  .addresses-select-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin-top: 10px;
    button {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 2px;
      scale: 1;
      transition: all 0.3s ease;
      outline: none;
      border: none;
      background: none;
      align-self: flex-end;
      cursor: pointer;

      &:hover {
        scale: 1.1;
      }
    }
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .checkout-adress-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 30px 20px;

    .checkout-adress-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      h2 {
        font-size: 20px;
        letter-spacing: 1.8px;
      }
      button {
        gap: 7px;
        padding: 10px 20px;
        font-size: 12px;
        letter-spacing: 1.5px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .primary-button {
      width: 30%;
      margin: 0;
      align-self: flex-start;
      div {
        font-size: 8px;
      }
    }

    .default-address {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;

      h6 {
        font-size: 14px;
        letter-spacing: 1.5px;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
        letter-spacing: 1px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
      }
    }

    .checkbox-list {
      .list {
        gap: 5px;
      }
    }

    .no-addresses {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 25px;

      h3 {
        font-size: 14px;
        letter-spacing: 1.2px;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
        letter-spacing: 1px;
      }

      .primary-button {
        width: 50%;
        height: auto;
        margin: 0;
        align-self: flex-start;
      }
    }
  }

  //ADDRESS-MODAL
  .address-modal-container {
    position: fixed;
    left: 50% !important;
    transform: translate(-50%, 20%);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 25px;
    max-height: 80vh;
    width: 90%;

    &::-webkit-scrollbar {
      width: 3px;
    }

    .modal-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      h3 {
        font-size: 16px;
        letter-spacing: 1.5px;
      }
      span {
        font-size: 10px;
        letter-spacing: 1.5px;
      }
    }
    #form-checkout-address {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    .adresses-edit {
      display: flex;
      width: 100%;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      h3 {
        font-size: 14px;
        letter-spacing: 1.5px;
      }
      h6 {
        font-size: 12px;
        letter-spacing: 1.2px;
      }
      .profile-edit-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        width: 100%;

        .two-fields-row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          gap: 15px;
          width: 100%;
        }

        .not-specified {
          font-size: 12px;
          width: 100%;
          padding: 8px;
        }

        #defaultBilling,
        #defaultDelivery {
          label {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            gap: 8px;
          }
        }
      }
      input,
      span {
        font-size: 12px;
        letter-spacing: 1.5px;
        padding: 2px 8px;

        &:disabled {
          padding: 5px 7px;
        }
      }

      button {
        font-size: 12px;
        letter-spacing: 2px;
      }
      label {
        font-size: 12px;
        letter-spacing: 1.5px;

        input {
          padding: 5px 7px;
        }
      }
      .adresses-edit-bottom {
        margin-top: 20px;

        button {
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  // ADDRESSES-MODAL
  //same as desktop

  .address-card {
    display: flex;
    width: 100%;
    padding: 20px;
    gap: 8px;

    h6 {
      font-size: 14px;
      letter-spacing: 1.5px;
    }
    p {
      font-size: 12px;
      letter-spacing: 1.5px;
    }

    .addresses-select-bottom {
      gap: 10px;
      margin-top: 10px;
      button {
        font-size: 12px;
        letter-spacing: 1.8px;
      }
    }
  }
}
