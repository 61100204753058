.catalog-container {
  // height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 20px 40px 20px 40px; // space for side bar
  gap: 50px;
  position: relative;
  .side-bar {
    display: flex;
    flex-direction: column;

    opacity: 1;
    transition: all 0.8s ease-out;

    .sidemenu-container {
      height: auto;
      width: 10vw;
    }

    &.hidden {
      opacity: 0;
    }
    .filters-visible {
      opacity: 1;
      transition: all 0.2s ease-out;
    }
    .filters-hidden {
      opacity: 0;
      transition: all 0.2s ease-out;
    }
  }
}
.black-overlay {
  filter: blur(5px);
  // filter: blur(5px) brightness(0.5);
  transition: all 0.5s ease-in;
  // background: #646466;
}

// RESPONSIVE
@media (max-width: 768px) {
  .catalog-container {
    // flex-direction: column;
    padding: 20px 10px;
    gap: 20px;
    .side-bar {
      display: none;
    }
  }
}
