.details-container {
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  column-gap: 20px;
  margin-top: 40px;
  width: 90%;

  .details-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 35px !important;
    row-gap: 7px;

    width: 45%;
    max-width: 600px;
    // height: 660px;
    text-transform: uppercase;
    letter-spacing: 1.7px;

    position: sticky;
    top: 10vh;
    z-index: 1;

    h3 {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.7px;
    }

    h6 {
      color: #818181;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 1.7px;
      cursor: pointer;

      display: inline-block;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #818181;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }

      &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }

      &.selected {
        border-bottom: 2px solid #818181;
        font-weight: 600;

        &::after {
          height: 0px;
        }
      }
    }

    .product-sku {
      font-size: 10px;
      font-weight: 300;
      letter-spacing: 1.5px;
    }

    li {
      cursor: pointer;
      transition: all 0.1s ease-out;
    }

    .product-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .prices {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 0;

        span {
          font-size: 12px;
          font-family: "Roboto", sans-serif;
          font-weight: 400;
          color: #818181;
        }
        .original-price {
          font-style: italic;
          font-size: 12px;
          color: #ce5b5b;
          text-decoration: line-through;
        }
      }

      h1 {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.8px;
      }
      span {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1.9px;
        text-transform: lowercase;

        span {
          font-size: 10px;
        }
      }
    }

    .status {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1.8px;

      &.instock {
        color: #26983f;
      }
      &.outofstock {
        color: #ff0000;
      }
    }

    .product-infos {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.7px;
      padding: 30px 0 15px 0;
      border-top: 1px solid #d9d9d9;
      gap: 100px;
    }

    .product-colors {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 25px 0 15px 0;
      border-top: 1px solid #d9d9d9;

      h6 {
        min-width: 220px;
        display: block;
        &::after {
          transform: unset;
          height: 0px;
          background-color: #818181;
        }
      }

      span {
        color: rgba(0, 0, 0, 0.8);
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 2px;
        align-self: end;
        cursor: pointer;
      }
      div {
        display: flex;

        ul {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          column-gap: 20px;
          row-gap: 7px;

          li {
            font-size: 12px;
            font-weight: 600;
            padding: 3px 7px;
            scale: 1;
            transition: all 0.2s ease-in;

            &:hover {
              scale: 1.1 !important;
            }
          }
        }
      }
      .colors-images {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 20px;

        img {
          width: 55px;
        }
        li {
          border: 1px solid #ffffff00;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            scale: 1.1 !important;
            opacity: 0.5;
            border: 1px solid #d7e0ff;
          }
        }
      }
    }
    .is-selected {
      background: #81818188;
      border-radius: 2px;
      color: #fff;
      transition: all 0.2s ease-in;

      &:hover {
        scale: 1.1 !important;
        opacity: 1;
      }
    }
    // .product-sizes {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 25px;
    //   padding: 30px 0 15px 0;
    //   border-bottom: 1px solid #d9d9d9;

    //   .size-unit {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-start;
    //     align-items: center;
    //     flex-wrap: wrap;
    //     gap: 30px;
    //     scale: 1;
    //     transition: all 0.2s ease-in;

    //     li:hover {
    //       font-weight: 600;
    //       color: #818181;
    //       scale: 1.1 !important;
    //     }
    //   }

    //   span {
    //     color: #818181;
    //     font-size: 12px;
    //     font-weight: 400;
    //     letter-spacing: 2.2px;
    //     align-self: flex-end;
    //   }
    // }

    //DROPDOWNS
    .details-dropdowns {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 20px;
      padding: 30px 0 15px 0;
      width: 100%;
      height: auto;
      min-height: 100px;
      // border-top: 1px solid #d9d9d9;

      .dropdowns-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;

        a {
          color: #818181;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 1.7px;
        }
      }

      .dropdowns-content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;

        .fade-in-right-active {
          // height: 9em;
          // max-height: 9em;
          height: 100%;
          transition: all 0.2s ease-in-out;
          // overflow-y: scroll;
          // overflow-x: hidden;

          &::-webkit-scrollbar {
            width: 3px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-track {
            background-color: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff;
            background-image: -webkit-linear-gradient(
              90deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(0, 0, 0, 1) 40%,
              transparent 100%,
              rgba(0, 0, 0, 1) 60%,
              transparent
            );
            border-radius: 10px;
          }
        }

        p {
          font-size: 10px;

          &.showDescription,
          &.showComposition,
          &.showDelivery {
            height: 100%;
            transition: opacity 0.2s ease;
            margin-top: 0;
            opacity: 1;

            transform: translateX(0%);
            transition: opacity 1.5s, transform 2.5s ease;
            white-space: pre-line;
          }
          &.hideDescription,
          &.hideComposition,
          &.hideDelivery {
            height: 0;
            padding: 0;
            margin-top: -1em;
            opacity: 0;

            transform: translateX(10%);
          }
        }
        a {
          color: #818181;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 1.7px;
        }
      }
    }
  }
  // OUT OF STOCK
  .fake-container {
    bottom: 0dvh;
    display: block;
    width: 100%;
  }
  .details-bottom {
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 0;
    border-top: 1px solid #d9d9d9;

    &-buttons {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      // position: relative;
    }

    span {
      color: rgb(247, 159, 159);
      text-transform: uppercase;
      text-align: center;
      padding: 0 10px;
    }

    .fill-heart,
    .heart,
    .heart-hidden {
      margin: 10px;
      font-size: 28px;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.2s ease;
      color: #000;
    }
    .instock {
      font-size: 35px;
    }
    .heart-hidden {
      visibility: hidden;
    }
  }
}
.he .details-content {
  direction: rtl;
}
//RESPONSIVE
@media (max-width: 768px) {
  .details-container {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0px;
    width: 100%;
    position: relative;

    .details-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      column-gap: 35px !important;
      row-gap: 7px;

      width: 100%;
      max-width: none;
      height: auto;
      letter-spacing: 1.2px;
      position: unset;

      h3 {
        font-size: 10px;
        letter-spacing: 1.5px;
      }
      h6 {
        font-size: 10px;
        letter-spacing: 1.2px;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 1px;
          bottom: 0;
          left: 0;
          background-color: #818181;
          transform-origin: bottom right;
          transition: transform 0.2s ease-out;
        }
        &:hover::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
        &.selected {
          border-bottom: 1px solid #818181;
          &::after {
            height: 0px;
          }
        }
      }

      .product-sku {
        font-size: 8px;
        font-weight: 300;
        letter-spacing: 1.3px;
      }

      .product-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 100px;

        .prices {
          padding-top: 10px;
          gap: 10px;
          span {
            font-size: 12px;
          }
          .original-price {
            font-size: 12px;
          }
        }

        h1 {
          font-size: 14px;
          letter-spacing: 1.5px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1.5px;
        }
      }

      .status {
        font-size: 10px;
        letter-spacing: 1.5px;
        padding: 0;
        margin-top: -25px;
        // margin-bottom: 50px;
        align-self: self-end;

        &.instock {
          color: #26983f;
        }
        &.outofstock {
          color: #ff0000;
        }
      }

      .product-infos {
        font-size: 12px;
        letter-spacing: 1.5px;
        padding: 30px 0 15px 0;
        gap: 100px;
      }

      .product-colors {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 20px 0 10px 0;

        h6 {
          min-width: 100px;
          display: block;
        }

        span {
          font-size: 10px;
          letter-spacing: 1.5px;
          margin-top: 10px;
        }
        div {
          ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 20px;
            row-gap: 7px;

            li {
              font-size: 10px;
              padding: 3px 7px;
            }
          }
        }
        .colors-images {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          column-gap: 20px;
        }
      }

      .product-sizes {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 30px 0 15px 0;

        .size-unit {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          gap: 30px;
        }
        span {
          font-size: 10px;
          letter-spacing: 1.5px;
          align-self: flex-end;
        }
      }

      //TABS
      .details-dropdowns {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        padding: 30px 0 15px 0;
        width: 100%;
        height: auto;
        min-height: 100px;

        .dropdowns-header {
          width: 100%;

          a {
            font-size: 10px;
            letter-spacing: 1.5px;
          }
        }

        .dropdowns-content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;

          .fade-in-right-active {
            height: auto;
            max-height: none;
            // max-height: 10em;
            transition: all 0.2s ease-in-out;
            overflow-y: scroll;
            overflow-x: hidden;

            &::-webkit-scrollbar {
              width: 2px;
            }
          }

          p {
            font-size: 8px;

            &.showDescription,
            &.showComposition,
            &.showDelivery {
              height: 100%;
              transition: opacity 0.2s ease;
              margin-top: 0;
              opacity: 1;

              transform: translateX(0%);
              transition: opacity 1.5s, transform 2.5s ease;
            }
            &.hideDescription,
            &.hideComposition,
            &.hideDelivery {
              height: 0;
              width: 0;
              padding: 0;
              margin-top: -1em;
              opacity: 0;

              transform: translateX(10%);
            }
          }
          a {
            font-size: 10px;
            letter-spacing: 1.5px;
          }
        }
      }
    }
    // OUT OF STOCK
    .fake-container {
      margin-left: -5vw;
    }
    .details-bottom {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 0;
      // position: fixed;
      // bottom: 0;
      // right: 0;
      // left: 0;
      // border-top: none;
      background: #fff;
      z-index: 9999999999999;
      width: 100vw;
      padding: 15px 0;
      margin-top: 0;
      // height: 10vh;

      .primary-button {
        margin-right: -20px;
      }

      .status {
        display: flex;
        justify-content: center;
        font-size: 10px;
        letter-spacing: 1.2px;
      }

      &-buttons {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;
        width: 100%;
        // position: relative;
      }

      .fill-heart,
      .heart,
      .heart-hidden {
        margin: 10px;
        font-size: 26px;
        transition: all 0.2s ease;
      }
      .instock {
        font-size: 30px;
      }
    }
  }
}
