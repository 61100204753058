.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // min-height: 100vh;
  // max-height: 100vh;
  margin: 0 0 50px 0;
  gap: 10px;
  padding: 0 20px;

  .registration-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100vw;
    padding: 10px 40px;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

    img {
      width: 65%;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .login-form {
    width: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    h3 {
      color: #515151;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 2.2px;
      text-transform: uppercase;
    }
    .two-fields-row {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 20px;
      width: 100%;
    }

    #salutation {
      margin-bottom: -15px;
    }

    .checkbox-container {
      margin-bottom: -10px;
    }

    #form-signup {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 25px;
      width: 100%;

      .dropdown {
        border: none;
        border-bottom: 1px solid #b8b8b8;

        .header svg {
          padding: 0;
        }
      }

      .checkbox-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        h4 {
          color: #515151;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        .list {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 50px;
          div {
            width: fit-content;
          }
        }

        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          width: fit-content;
          color: #b8b8b8;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
        input {
          width: auto;
        }
      }

      input[type="checkbox"] {
        width: auto;
        filter: sepia(0%) brightness(100%)
          hue-rotate(300deg) saturate(99%) contrast(300%);
      }
      input[type="checkbox"]:checked {
        filter: sepia(80%) brightness(100%)
          hue-rotate(300deg) saturate(99%) contrast(300%);
        accent-color: #0080ff;
      }

      .checkbox-container {
        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          color: #b8b8b8;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 1.5px;
          text-transform: uppercase;
        }
      }

      div {
        width: 100%;
        label {
          width: inherit;
          color: #515151;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 1.8px;
          text-transform: uppercase;
        }
        input {
          width: 100%;
          text-transform: uppercase;
          border: none;
          border-bottom: 1px solid #b8b8b8;
          outline: none;
          padding: 5px 0px;
          color: #515151;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 2px;
          transition: all 0.3s ease-in;
          &::placeholder {
            color: #cfcfcf;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
        }
      }
    }

    #language {
      .list {
        gap: 20px !important;
      }
    }
  }

  .login-form-footer {
    margin-top: 25px;
    width: 100%;
    text-align: center;
    a {
      font-size: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .signup-error {
      display: block;
      color: red;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
  }

  //ERROR
  input {
    &.error {
      border: none !important;
      border-bottom: 1px solid red !important;
      padding: 10px 5px !important;

      &::placeholder {
        color: red !important;
        opacity: 0.4;
      }
    }
  }

  .errorLabel {
    color: red !important;
    font-weight: bolder !important;
  }
}

//HEBREW
.he .login-form-container {
  direction: rtl;
}
.he input[type="tel"] {
  direction: rtl !important;
}

//RESPONSIVE
@media (max-width: 768px) {
  .login-form-container {
    width: 100%;
    height: 100%;
    margin: 0px 0 30px 0;
    padding: 0;

    .registration-header {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;

      img {
        width: 50%;
      }
    }

    .login-form {
      width: 100%;
      align-items: center;
      gap: 30px;
      padding: 0 20px;

      h3 {
        font-size: 20px;
        letter-spacing: 2.2px;
      }

      #form-signup {
        align-items: center;

        .checkbox-list {
          h4 {
            font-size: 14px;
            letter-spacing: 2px;
          }

          label {
            font-size: 12px;
            letter-spacing: 1.2px;
          }
        }

        .checkbox-container {
          label {
            font-size: 12px;
            letter-spacing: 1.2px;
          }
        }

        div {
          label {
            font-size: 14px;
            letter-spacing: 1.2px;
          }
          input {
            font-size: 14px;
            letter-spacing: 1.2px;
            &::placeholder {
              font-size: 14px;
              letter-spacing: 1.2px;
            }
          }
        }
      }
    }

    .login-form-footer {
      a {
        font-size: 14px;
        letter-spacing: 1.2px;
      }
      .signup-error {
        font-size: 10px;
        letter-spacing: 1px;
        margin-bottom: 30px;
      }
    }
  }
}
