.cart-container {
  display: flex;
  flex-direction: column;
  gap: 50px;

  // overflow-y: scroll;
  // height: fit-content;
  height: 100%;
  width: 570px;
  background: #fff;
  text-transform: uppercase;
  // padding: 0 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;

    span {
      &:nth-child(2) {
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .items-list {
    display: flex;
    flex-direction: column;
    gap: 70px;
    height: 100%;
    overflow-y: scroll;
    padding: 0 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .item-card {
    display: flex;
    align-items: center;
    gap: 25px;

    img {
      height: 120px;
      max-height: 120px;
      min-height: 120px;
      max-width: 100px;
      min-width: 100px;
      width: 100px;
      object-fit: contain;
      border-radius: 3px;
    }
    .item-infos {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3,
        span {
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        span {
          color: #26983f;
        }
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          .original-price {
            font-style: italic;
            font-size: 13px;
            color: #ce5b5b;
            text-decoration: line-through;
          }
        }
      }
    }
    .item-details {
      display: flex;
      flex-direction: column;
      gap: 0px;

      span,
      h4 {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.7px;
      }
      .item-sku {
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 1.5px;
      }

      .dropdown-container {
        display: flex;
        flex-direction: row;
        .dropdown {
          width: 50%;
          display: flex;
          position: relative;
          cursor: pointer;
          height: fit-content;
          border-style: none;

          .header {
            width: 100%;
            cursor: pointer;
            font-size: 15px;
            .icon {
              display: none;
            }
          }
        }
        .dropdown-body {
          right: 0;
        }
        .open {
          width: max-content;
          top: 35px;
          right: 0;
        }

        .dropdown {
          .header,
          input {
            text-align: end;
            font-size: 12px;
          }

          .dropdown-item {
            padding: 0 5px;
            font-size: 12px;
          }
        }
      }
    }
    .edit-item {
      display: flex;
      flex-direction: column;

      span {
        color: lightgrey;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 2.2px;
        cursor: pointer;
      }
    }
  }

  .cart-footer {
    display: flex;
    flex-direction: column;
    gap: 25px;

    input {
      color: #cfcfcf;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      border: none;
      border-bottom: 2px solid #cfcfcf;

      &:focus {
        outline: none;
      }
    }
    .valid {
      border-bottom: 2px solid #26983f;
      color: #26983f;
    }
    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 2px;

        &:nth-child(2) {
          color: #26983f;
        }
      }
    }

    .btn-42 {
      color: #fff !important;
      border: 2px solid #000;
    }
    .primary-button {
      width: 100%;
      color: #fff;
    }
    .primary-button {
      margin-top: 0;
      width: 100%;
    }
    .btn-42:hover {
      a,
      div,
      span {
        color: #000 !important;
        opacity: 1;
        transition: all 0.3s ease;
        font-weight: 400;
      }
    }

    .login-button {
      font-size: 18px;
      // font-weight: 600;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      transition: all 0.3s ease-in-out;
    }
  }
  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    img {
      height: 150px;
      margin-bottom: 20px;
    }
    h6 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 2px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 2px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin-top: 70px;

      .btn-41 {
        color: #000 !important;
        border: 2px solid #000;
      }
      .secondary-button {
        width: 100%;
        color: #000;
      }
      .primary-button {
        margin-top: 0;
        width: 100%;
      }
      .btn-41:hover {
        a,
        div,
        span {
          color: #fff !important;
          opacity: 1;
          transition: all 0.3s ease;
          font-weight: 400;
        }
      }

      .login-button {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

//HEBREW
.he {
  .cart-container {
    direction: rtl;
  }
}

//RESPONSIVE
@media (max-width: 768px) {
  .cart-container {
    height: 100%;
    width: 100%;

    .header {
      font-size: 14px;
      letter-spacing: 1.5px;
    }

    .items-list {
      gap: 70px;
      overflow-y: scroll;
    }

    .item-card {
      display: flex;
      align-items: center;
      gap: 20px;

      img {
        height: 100px;
        max-height: 100px;
        min-height: 100px;
        max-width: 90px;
        min-width: 90px;
        width: 90px;
      }
      .item-infos {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .title {
          gap: 15px;
          h3,
          span {
            font-size: 14px;
            letter-spacing: 1.5px;
          }
        }
      }
      .item-details {
        display: flex;
        flex-direction: column;
        gap: 0px;

        span,
        h4 {
          font-size: 10px;
          letter-spacing: 1.2px;
        }
        .item-sku {
          font-size: 8px;
          letter-spacing: 1.2px;
        }

        .dropdown-container {
          display: flex;
          flex-direction: row;
          .dropdown {
            width: 50%;
            display: flex;
            position: relative;
            height: fit-content;

            .header {
              width: 100%;
              font-size: 15px;
              .icon {
                display: none;
              }
            }
          }
          .dropdown-body {
            right: 0;
          }
          .open {
            width: max-content;
            top: 35px;
            right: 0;
          }

          .dropdown {
            .header,
            input {
              text-align: end;
              font-size: 12px;
            }

            .dropdown-item {
              padding: 0 5px;
              font-size: 12px;
            }
          }
        }
      }
      .edit-item {
        span {
          font-size: 10px;
          letter-spacing: 1.8px;
        }
      }
    }

    .cart-footer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input {
        font-size: 14px;
        letter-spacing: 1.8px;
      }

      .total {
        span {
          letter-spacing: 1.8px;
        }
      }

      .login-button {
        font-size: 18px;
        letter-spacing: 1.2px;
      }
    }
    .empty-cart {
      img {
        height: 130px;
      }
      h6 {
        font-size: 16px;
        letter-spacing: 1.8px;
      }

      span {
        font-size: 14px;
        letter-spacing: 1.8px;
        text-align: center;
      }

      .buttons {
        margin-top: 50px;

        .login-button {
          font-size: 16px;
          letter-spacing: 1.2px;
        }
      }
    }
  }
}
